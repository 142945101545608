import { type ScrollTriggerAnimation, useAnimations } from "./animations";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export enum AccountAnimations {
  TOP_BAR = "account-top-bar"
}

export enum AccountAnimatables {
  NAME = "name",
  PROFILE_IMAGE = "profile-image",
  MOTION_PATH = "motion-path",
  TOP_BAR = "account-top-bar"
}

export type TopBarAnimation = ScrollTriggerAnimation<AccountAnimations.TOP_BAR, AccountAnimatables>;

export const useAccountAnimations = () => {
  const { getByName } = useAnimations();
  const accountAnimation: TopBarAnimation | null = getByName(AccountAnimations.TOP_BAR);

  if (accountAnimation?.scroller) {
    const elements = accountAnimation.elements;
    accountAnimation.timeline = gsap
      .timeline({ paused: true })
      .addLabel("start")
      .to(
        elements[AccountAnimatables.PROFILE_IMAGE],
        {
          motionPath: {
            path: elements[AccountAnimatables.MOTION_PATH] as SVGPathElement,
            align: elements[AccountAnimatables.MOTION_PATH] as SVGPathElement,
            alignOrigin: [0, 0],
            start: 1,
            end: 0
          },
          scale: 0.55,
          duration: 0.2
        },
        "start"
      )
      .to(elements[AccountAnimatables.TOP_BAR], { paddingBottom: 64, duration: 1 }, "start");
    const nameElement = elements[AccountAnimatables.NAME];
    if (nameElement) {
      accountAnimation.timeline.to(
        nameElement,
        { left: 60 + nameElement.clientWidth / 2, fontSize: 20, duration: 1 },
        "start"
      );
    }
    accountAnimation.timeline.addLabel("end");

    ScrollTrigger.create({
      scroller: accountAnimation.scroller,
      trigger: accountAnimation.trigger,
      start: "64px 180px",
      toggleActions: "restart none none reverse",
      end: () => `64px 64px`,
      fastScrollEnd: true,
      animation: accountAnimation.timeline,
      scrub: 0.1
    });
  }
};
