<template lang="pug">
div
  div.d-flex.flex-wrap.pt-5.gap-1(v-if="configuration.addedComponents.length || configuration.removedComponents.length || (configuration.mandatoryComponents && configuration.mandatoryComponents.length) || (configuration.articleAttributes && Object.keys(configuration.articleAttributes).length)")
    div.d-flex.align-center.flex-shrink-0.px-2.py-1.border.rounded(v-for="component in configuration.addedComponents") 
      div.pe-1 {{ component.name }} 
      EatIcon(icon="$plus" :size="16") 
    div.d-flex.align-center.flex-shrink-0.px-2.py-1.border.rounded(v-for="component in configuration.removedComponents") 
      div.pe-1 {{ component.name }} 
      EatIcon(icon="$minus" :size="16")
    div.d-flex.align-center.flex-shrink-0.px-2.py-1.border.rounded(v-for="component in configuration.mandatoryComponents") 
      div.pe-1 {{ component.name }} 
      EatIcon(:icon="component.addingPrice >= 0 ? '$plus' : '$minus'" :size="16")
    template(v-for="attribute, i in configuration.articleAttributes" :key="i")
      div.d-flex.align-center.flex-shrink-0.px-2.py-1.border.rounded(v-if="isOrderItemArticleAttribute(attribute)" )
        div(:class="{ 'pe-1': attribute.fieldType !== 'string' }") {{ attribute.name + (attribute.fieldType === 'string' ? `: ${attribute.attributeValue}` : '' )}}
        div.font-weight-bold(v-if="attribute.fieldType === 'number'") {{ attribute.attributeValue }}
        EatIcon(icon="$check" :size="16" v-else-if="attribute.fieldType === 'boolean'")
      div.d-flex.align-center.flex-shrink-0.px-2.py-1.border.rounded(v-if="isCartItemArticleAttribute(attribute)") 
        div(:class="{ 'pe-1': typeof attribute.value !== 'string' }") {{ attribute.name + (typeof attribute.value === 'string' ? `: ${attribute.value}` : '' )}}
        div.font-weight-bold(v-if="typeof attribute.value === 'number'") {{ attribute.value }}
        EatIcon(icon="$check" :size="16" v-else-if="typeof attribute.value === 'boolean'") 
  div.pt-5(v-if="product.isModular")
    div.text-paragraph {{ configurationModulesToString }} 
  div.d-flex.flex-wrap.gap-1.mt-3(v-if="configurationAllergens.length")
    AllergenChip.flex-grow-0(v-for="allergen in configurationAllergens" :key="allergen.code" :allergen="allergen")
</template>

<script setup lang="ts">
import { computed, type PropType } from "vue";
import type { CartItem } from "@/models/Cart";
import type { OrderItem, OrderItemsArticleAttribute } from "@/models/Order";
import AllergenChip from "@/components/AllergenChip.vue";
import type { ConfigurationArticleAttribute } from "@/models/Product";

const props = defineProps({
  item: {
    type: Object as PropType<CartItem | OrderItem>,
    required: true
  }
});

const isOrderItemArticleAttribute = (attribute: unknown ): attribute is OrderItemsArticleAttribute => {
  return 'attributeValue' in (attribute as ConfigurationArticleAttribute | OrderItemsArticleAttribute);
}

const isCartItemArticleAttribute = (attribute: unknown): attribute is ConfigurationArticleAttribute => {
  return 'value' in (attribute as ConfigurationArticleAttribute | OrderItemsArticleAttribute);
}

const configuration = computed(() => {
  return props.item && "configuration" in props.item ? props.item.configuration : props.item;
});

const product = computed(() => {
  return props.item && "product" in props.item ? props.item.product : props.item;
});

const configurationModulesToString = computed(() => {
  let mustReturn = "";
  if ("choosenProducts" in configuration.value && configuration.value.choosenProducts) {
    configuration.value.choosenProducts.forEach(product => {
      mustReturn += mustReturn ? `, ${product.name}` : product.name;
    });
  }
  if ("upsellingProducts" in configuration.value && configuration.value.upsellingProducts) {
    configuration.value.upsellingProducts.forEach(product => {
      mustReturn += mustReturn ? `, ${product.name}` : product.name;
    });
  }
  if ("modules" in configuration.value && configuration.value.modules) {
    Object.values(configuration.value.modules).forEach(products => {
      products.forEach(product => {
        mustReturn += mustReturn ? `, ${product.name}` : product.name;
      });
    });
  }
  if ("upsellingModules" in configuration.value && configuration.value.upsellingModules) {
    Object.values(configuration.value.upsellingModules).forEach(products => {
      products.forEach(product => {
        mustReturn += mustReturn ? `, ${product.name}` : product.name;
      });
    });
  }
  return mustReturn;
});

const configurationAllergens = computed(() => {
  const allergens = "allergens" in product.value ? product.value.allergens || [] : [];
  const modulesProducts =
    "modules" in configuration.value ? Object.values(configuration.value.modules) : [];
  const upsellingModulesProducts =
    "modules" in configuration.value ? Object.values(configuration.value.upsellingModules) : [];
  const modulesAllergens = [
    ...modulesProducts.flat(),
    ...upsellingModulesProducts.flat()
  ].flatMap(product => product.allergens || []);
  return [...allergens, ...modulesAllergens];
});
</script>

<style lang="scss" scoped>
.components__container {
  flex-wrap: wrap;
  .outlined--cart-chips {
    border: 1px solid var(--border-clr--type-a);
    color: var(--border-clr--type-a);
    &:not(:first-of-type) {
      margin-left: 4px;
      margin-bottom: 4px;
    }
  }
}
</style>
