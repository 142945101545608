<template lang="pug">
EatView.position-relative.bg-neutral-a
  template(v-slot:default)
    div.eat-profile.bg-white(v-scrollable="true" v-scroll-trigger="{ animations: [AccountAnimations.TOP_BAR], role: ScrollTriggerRoles.SCROLLER }")
      div.white-space.rounded-t-xl

      nav.mb-6.profile-nav.px-3(v-scroll-trigger="{ animations: [AccountAnimations.TOP_BAR], role: ScrollTriggerRoles.TRIGGER }")
        div.profile-nav__row
          router-link.profile-nav__link.px-4.py-3.rounded-lg.bg-primary(:to="{ name: 'user.profileSettings' }") 
            EatIcon.mr-1(icon="$peopleLight")
            div.ps-1.profile-nav__link-text {{ $t("user.account.profile") }}
          router-link.profile-nav__link.px-4.py-3.rounded-lg.bg-primary(:to="{ name: 'user.fidelityCards' }") 
            EatIcon.mr-1(icon="$fidelity")
            div.ps-1.profile-nav__link-text {{ $t("user.account.fidelityCards") }}
        div.div.flex-shrink-1.profile-nav__row
          router-link.profile-nav__link.px-4.py-3.rounded-lg.bg-primary(:to="{ name: 'user.deliveryAddress' }") 
            EatIcon.mr-1(icon="$point")
            div.ps-1.profile-nav__link-text {{ $t("user.account.deliveryAddresses") }}
          router-link.profile-nav__link.px-4.py-3.rounded-lg.bg-primary(:to="{ name: 'user.promoCodes'}")
            EatIcon.mr-1(icon="$promotion")
            div.ps-1.profile-nav__link-text {{ $t("user.account.promotions") }}
            
      EatTabs(:tabs="tabs") 
      
  template(v-slot:float-button)  
    EatFloatBtn(v-if="$route.name === 'user.orders'" icon="$cartLight" :to="{ name: 'order' }")
      span {{ $t('user.account.floatBtn.orderNow') }}
    EatFloatBtn(v-if="$route.name === 'user.reservations'" icon="$calendarLight" :to="{ name: 'reservation' }")
      span {{ $t('user.account.floatBtn.reserveNow') }}

</template>

<script setup lang="ts">
import { nextTick, onMounted } from "vue";
import EatTabs, { type Tab } from "@/layout/partials/EatTabs.vue";
import { AccountAnimations, useAccountAnimations } from "@/store/animations/account";
import { ScrollTriggerRoles } from "@/store/animations/animations";
import { useUser } from "@/store/user";
import { setAppTitle } from "@/store/appTitle";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const router = useRouter();
const route = useRoute();
const i18n = useI18n();

setAppTitle(i18n.t("user.account.appTitle").toString());

const tabs: Tab[] = [
  { title: "user.account.orders", routeName: "user.orders" },
  { title: "user.account.reservations", routeName: "user.reservations" }
];

onMounted(async () => {
  if (route.name == "user") router.push({ name: "user.orders" });
  nextTick(() => useAccountAnimations());
});
</script>

<style lang="scss" scoped>
$height-unit: 64px;

.eat-profile {
  .white-space {
    height: 150px;
  }

  .profile-nav {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: calc(#{$height-unit} + 12px);
    .profile-nav__row {
      display: flex;
      overflow: hidden;
      gap: 0.5rem;
      .profile-nav__link {
        text-decoration: none;
        max-width: calc(100% / 2);
        flex: 0 1;
        display: flex;
        justify-content: flex-start;
        .profile-nav__link-text {
          flex: 0 1 min-content;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
