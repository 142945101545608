<template lang="pug">
EatView 
  div.bg-neutral-a.flex-grow-1(v-scrollable="true")
    div.fidelity-card-container.mt-5(v-if="user && user.fidelityCard")
      div.fidelity-qr-code-background.bg-white.rounded-xxl
      div.fidelity-qr-code.text-center.pt-2.pb-10.mx-auto(v-if="user && user.fidelityCard")
        div(v-html="user.fidelityCard.qrcode" style="width: 240px; height: 240px;" v-if="user && user.fidelityCard")
        ApplePassBtn(v-if="isDeviceIOSAndBrowserSafari" :isDownloading="isDownloadingPkPass" @click="addToApplePay")
        div.open-in-safari__message.text-body-2.mb-2.mx-auto(v-else) {{ $t('user.fidelityCards.pleaseOpenInSafari') }}
        
      div.fidelity-card.bg-primary.rounded-xl.pa-6
        div.fidelity-card-background-overlay
        EatImage.fidelity-card-logo.rounded(v-if="location?.logoUrl" :src="location.logoUrl" aspectRatio="1/1")
        div.fidelity-card-membership.overflow-hidden.pe-2
          div.text-caption.ellipsis {{$t('user.fidelityCards.membership')}} 
          div.text-body-1.ellipsis.text-uppercase.font-weight-medium {{ user.fidelityCard.affiliation || "-- --" }}
        div.fidelity-card-number.overflow-hidden.pe-2
          div.text-caption.ellipsis {{$t('user.fidelityCards.cardNumber')}} 
          div.text-body-1.ellipsis.font-weight-medium {{ user.fidelityCard.code.toUpperCase() }}
        div.fidelity-card-data.overflow-hidden
          div
            div.text-caption {{$t('user.fidelityCards.cardPoints')}} 
            div.text-body-1.font-weight-medium {{ user.fidelityCard.points || 0 }}
          div
            div.text-caption {{$t('user.fidelityCards.prepaidAmount')}}
            div.text-body-1.font-weight-bold {{ formatPrice(user.fidelityCard.prepaidAmount || 0) }} 
          div.px-3
            div.text-caption {{$t('user.fidelityCards.prepaidAmountTicket')}}
            div.text-body-1.font-weight-bold {{ formatPrice(user.fidelityCard.prepaidAmountTicket || 0) }} 
      

    RoundedContainer.my-2(
      :title="$t('user.fidelityCards.lastYearMovements')"
      :sticky-title="true"
      :horizontalPadding="false"
    )
      Loading.mt-8.mb-12(v-if="loading")
      EatUl(v-else-if="fidelityCardMovements?.length")
        EatLi(v-for="movement,  i of fidelityCardMovements" :key="i")
          div.d-flex.align-center
            div.d-flex(style="gap: 24px")
              div(v-if="movement.pointsVariation")
                div {{ $t('user.fidelityCards.cardPoints') }}
                div.font-weight-medium(:class="movement.amountVariation > 0 ? 'success--text' : 'error--text'") {{ movement.pointsVariation > 0 ? '+'+movement.pointsVariation : movement.pointsVariation }}
              div(v-if="movement.amountVariation")
                div {{ $t('user.fidelityCards.prepaidAmount') }}
                div.font-weight-medium(:class="movement.amountVariation > 0 ? 'success--text' : 'error--text'") {{ formatPrice(movement.amountVariation || 0, { sign: 'exceptZero' }) }}
              div(v-if="movement.ticketAmountVariation")
                div {{ $t('user.fidelityCards.prepaidAmountTicket') }}
                div.font-weight-medium(:class="movement.amountVariation > 0 ? 'success--text' : 'error--text'") {{ formatPrice(movement.ticketAmountVariation || 0, { sign: 'exceptZero' }) }}
            div.ms-auto.pl-2.flex-grow-0.overflow-hidden.text-right
              div.ellipsis {{ movement.locationName }}
              div.ellipsis.font-weight-medium {{ formatCreatedDate(movement.log.createdDate)  }}
          div.text--secondary {{ movement.reason }}
        EatLi(v-if="initialBalance")
          div.text-center.font-weight-medium.pt-4 {{ $t('user.fidelityCards.initialBalance') }} {{ initialBalance.date }}
          div.px-4.py-3.d-flex.justify-space-between(v-if="user && user.fidelityCard")
            div
              div.text-body-1 {{$t('user.fidelityCards.cardPoints')}} 
              div.text-body-1.font-weight-medium {{ initialBalance.points || 0 }}
            div
              div.text-body-1 {{$t('user.fidelityCards.prepaidAmount')}}
              div.text-body-1.font-weight-bold {{ formatPrice(initialBalance.prepaidAmount || 0) }} 
            div
              div.text-body-1 {{$t('user.fidelityCards.prepaidAmountTicket')}}
              div.text-body-1.font-weight-bold {{ formatPrice(initialBalance.prepaidAmountTicket || 0) }}
      div.pt-8.px-10.pb-12.text-center(v-else) {{ $t("user.fidelityCards.noMovements") }} 
</template>

<script setup lang="ts">
import ApplePassBtn from "@/components/buttons/ApplePassBtn.vue";
import { setAppTitle } from "@/store/appTitle";
import { useUser } from "@/store/user";
import { downloadPkPass } from "@/utils/downloadFile";
import { isDeviceIOSAndBrowserSafari } from "@/utils/userAgentData";
import { computed, onMounted, type Ref, ref } from "vue";
import { get as getPKpass } from "@/api/AppleWalletPass";
import { formatPrice } from "@/utils/formatPrice";
import { DateTime } from "luxon";
import { useApp } from "@/store/app";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
setAppTitle(i18n.t("user.fidelityCards.title").toString());
const loading = ref(true);
const { location } = useApp();

const { user, fidelityCardMovements, refreshUser, loadFidelityCardMovements } = useUser();
onMounted(async () => {
  loading.value = true;
  await refreshUser();
  await loadFidelityCardMovements();
  loading.value = false;
});

const isDownloadingPkPass: Ref<boolean> = ref(false);

async function addToApplePay() {
  if (isDeviceIOSAndBrowserSafari) {
    isDownloadingPkPass.value = true;
    try {
      const pkpass = await getPKpass();
      downloadPkPass(pkpass);
    } catch {
      throw new Error(i18n.t("app.snackbar.errorDownloadFile") as string);
    } finally {
      isDownloadingPkPass.value = false;
    }
  }
}

const formatCreatedDate = (date: string) => {
  return DateTime.fromISO(date).toFormat('dd LLL yy, HH:mm', { locale: i18n.locale.value });
}

const initialBalance = computed(() => {
  const pointsVariation =
    fidelityCardMovements.value?.reduce(
      (total, movement) => total + (movement.pointsVariation || 0),
      0
    ) || 0;
  const amountVariation =
    fidelityCardMovements.value?.reduce(
      (total, movement) => total + (movement.amountVariation || 0),
      0
    ) || 0;
  const ticketVariation =
    fidelityCardMovements.value?.reduce(
      (total, movement) => total + (movement.ticketAmountVariation || 0),
      0
    ) || 0;
  const date = DateTime.now()
    .minus({ years: 1 })
    .toFormat("dd MMM yyyy", { locale: i18n.locale.value });
  return {
    date: date,
    points: (user.value?.fidelityCard?.points || 0) - pointsVariation,
    prepaidAmount: (user.value?.fidelityCard?.prepaidAmount || 0) - amountVariation,
    prepaidAmountTicket: (user.value?.fidelityCard?.prepaidAmountTicket || 0) - ticketVariation
  };
});
</script>

<style lang="scss" scoped>
.fidelity-card-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-rows: repeat(3, auto);
  .fidelity-card {
    position: relative;
    box-shadow: 17px 19px 12px -12px rgba(0, 0, 0, 0.2),
      inset -12px 4px 2px -10px rgba(0, 0, 0, 0.2), inset 3px -2px 2px -1px rgba(255, 255, 255, 0.4);
    width: 90%;
    @media screen and (min-width: 456px) {
      aspect-ratio: 16/9;
    }

    grid-column: 1;
    grid-row: 1 / 3;
    justify-self: center;

    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    gap: 16px;

    .fidelity-card-background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(250deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 60%);
      z-index: 0;
    }

    .fidelity-card-membership {
      grid-column: 1;
      grid-row: 1 / 2;
      max-width: 180px;
    }

    .fidelity-card-logo {
      z-index: 1;
      min-width: 80px;
      max-width: 112px;
      grid-column: 3;
      grid-row: 1 / 3;
      justify-self: end;
    }

    .fidelity-card-number {
      grid-column: 1;
      grid-row: 2;
    }

    .fidelity-card-data {
      grid-column: 1 / 4;
      grid-row: 3;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-self: end;
    }
  }

  .fidelity-qr-code-background {
    grid-column: 1;
    grid-row: 2 / -1;
  }

  .fidelity-qr-code {
    grid-column: 1;
    grid-row: 3 / -1;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.open-in-safari__message {
  max-width: 25ch;
  line-height: 1.2rem;
}
</style>
  