import type { Category } from "@/models";
import type { CartItem } from "@/models/Cart";
import type { OrderItem } from "@/models/Order";
import type {
  AddableComponent,
  ConfigurationArticleAttribute,
  MandatoryComponent,
  ModuleProduct,
  Product,
  RemovableComponent,
  StringArticleAttribute
} from "@/models/Product";
import { useCartItems } from "../cart/cartItems";

export function useRestoreOrderCart() {
  const restoreArticleAttributes = (product: Product, item: OrderItem) => {
    const articleAttributes: Record<string, ConfigurationArticleAttribute> = {};

    product?.articleAttributes?.forEach(articleAttribute => {
      // Recupera gli article attributes presenti nell'OrderItem
      const itemArticleAttribute = item.articleAttributes.find(itemAttribute => {
        const isSameAttribute = itemAttribute.attributeTypeId === articleAttribute.attributeTypeId;
        const hasSameFieldType = itemAttribute.fieldType === articleAttribute.fieldType;
        if (!isSameAttribute || !hasSameFieldType) return false;

        // Si assicura che nel caso di fieldType === "string" il valore selezionato nell'OrderItem sia ancora selezionabile sul prodotto attuale
        let valueIsStillAvailable = true;
        if (itemAttribute.fieldType === "string") {
          const stringArticleAttribute = articleAttribute as StringArticleAttribute;
          valueIsStillAvailable = stringArticleAttribute.valueSet.includes(
            itemAttribute.attributeValue as string
          );
        }
        return valueIsStillAvailable;
      });

      if (itemArticleAttribute)
        articleAttributes[articleAttribute.attributeTypeId] = {
          name: itemArticleAttribute.name,
          value: itemArticleAttribute.attributeValue
        };
    });

    return articleAttributes;
  };

  const restoreAddedComponents = (product: Product, item: OrderItem) => {
    const addedComponents: AddableComponent[] = [];
    if (product.isModular) return addedComponents;

    product.addableComponents.forEach(component => {
      const itemComponent = item.addedComponents.some(
        itemComponent => itemComponent.productId === component.productId
      );
      if (itemComponent) addedComponents.push(component);
    });

    return addedComponents;
  };

  const restoreRemovedComponents = (product: Product, item: OrderItem) => {
    const removedComponents: RemovableComponent[] = [];
    if (product.isModular) return removedComponents;

    product.removableComponents.forEach(component => {
      const itemComponent = item.removedComponents.some(
        itemComponent => itemComponent.productId === component.productId
      );
      if (itemComponent) removedComponents.push(component);
    });

    return removedComponents;
  };

  const restoreMandatoryComponents = (product: Product, item: OrderItem) => {
    const mandatoryComponents: MandatoryComponent[] = [];
    if (product.isModular) return mandatoryComponents;

    product.mandatoryComponentsGroups?.forEach(mandatoryComponentsGroup => {
      mandatoryComponentsGroup.components.forEach(component => {
        const itemComponent = item.addedComponents.some(
          itemComponent => itemComponent.productId === component.productId
        );
        if (itemComponent) mandatoryComponents.push(component);
      });
    });

    return mandatoryComponents;
  };

  const restoreModulesAndUpsellingModules = (product: Product, item: OrderItem) => {
    const modules: Record<string, ModuleProduct[]> = {};
    const upsellingModules: Record<string, ModuleProduct[]> = {};

    if (product.isModular) {
      product.modules.forEach(module => {
        module.products.forEach(moduleProduct => {
          const itemModuleProduct = item.choosenProducts?.some(
            choosenProduct => choosenProduct.productId === moduleProduct.id
          );
          if (!modules[module.name]) modules[module.name] = [];
          if (!itemModuleProduct) return;
          if (module.maxCustomerChoices && modules[module.name].length < module.maxCustomerChoices)
            modules[module.name].push(moduleProduct);
        });

        if (modules[module.name]?.length === module.maxCustomerChoices) {
          module.products.forEach(moduleProduct => {
            if (!moduleProduct.priceUpselling) return;
            const itemUpsellingProduct = item.upsellingProducts?.some(
              choosenProduct => choosenProduct.productId === moduleProduct.id
            );
            if (!upsellingModules[module.name]) upsellingModules[module.name] = [];
            if (!itemUpsellingProduct) return;
            upsellingModules[module.name].push(moduleProduct);
          });
        }

        if (
          module.minCustomerChoices !== null &&
          module.minCustomerChoices !== undefined &&
          modules[module.name].length < module.minCustomerChoices
        )
          delete modules[module.name];
      });
    }

    return { modules, upsellingModules };
  };

  const restoreCartItemsFromOrderItems = (items: OrderItem[], categories: Category[]) => {
    items.forEach(item => {
      const category = categories.find(category => category.id === item.categoryId);
      const product = category?.products.find(product => product.id === item.productId);
      if (!product) return;

      const cartItem: CartItem = {
        product,
        configuration: {
          addedComponents: restoreAddedComponents(product, item),
          removedComponents: restoreRemovedComponents(product, item),
          mandatoryComponents: restoreMandatoryComponents(product, item),
          articleAttributes: restoreArticleAttributes(product, item),
          ...restoreModulesAndUpsellingModules(product, item)
        },
        quantity: item.quantity,
        variation: undefined
      };

      const { addToCart } = useCartItems();
      addToCart(cartItem);
    });
  };

  return { restoreCartItemsFromOrderItems };
}
