import type { Order } from "@/models/Order";
import { ref } from "vue";

const order = ref<Order>();

const useOrderDetail = () => {
  const setOrder = (newProduct: Order) => {
    order.value = newProduct;
  };

  const reset = () => {
    order.value = undefined;
  };

  return {
    order,
    setOrder,
    reset
  };
};

export default useOrderDetail;
