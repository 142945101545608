<template lang="pug">
EatView.bg-neutral-a(:loading="viewLoading")
  template(v-slot:default)
    teleport(to="#topBarAction")
      EatIconBtn(icon="$pointPlus" :to="{ name: `${baseRoute}.newAddress` }")
    div.d-flex.flex-column.flex-grow-1(v-scrollable="false")
      AddressSearchBar.flex-grow-0

      div.flex-grow-1.d-flex.flex-column(v-scrollable="true")
        
        div.bg-neutral-a.flex-grow-1(v-if="autocompletes && autocompletes.length")
          EatUl
            EatLi.d-flex.cursor-pointer(v-for="autocomplete, i in autocompletes" :key="i" @click="onSelectedAutcomplete(autocomplete)") 
              EatIcon.me-4(icon="$point") 
              span {{ autocomplete.address }}

        div.pb-6.pt-2.flex-grow-1.bg-neutral-a(v-else)
          AddressList(v-if="deliveryAddresses && deliveryAddresses.length" :baseRoute="baseRoute" :title="$t('deliveryAddress.addressList')" :addressList="deliveryAddresses" @select-delivery-address="onChangeDeliveryAddress" @delete-address="onDeleteAddress")
          
          div.d-flex.flex-column.align-center.mt-6.mb-8(v-else)
            EatImage.placeholder-image.mb-2.rounded-circle(:src="deliveryImage")
            div.placeholder__text {{ $t('deliveryAddress.noAddressesMessage') }} 

          RoundedContainer.mt-2.mb-15(:title="$t('deliveryAddress.deliveryDetails')" :horizontal-padding="false" v-if="isDeliverySelector && deliveryAddresses.length")
            EatUl
              EatLi.d-flex(v-if="maxDeliveryDistance")
                span.me-auto {{ $t('deliveryAddress.maxDeliveryDistance') }}
                span max {{ maxDeliveryDistance }} km
              EatLi.d-flex(v-if="selectedDeliveryMinPurchase")
                span.me-auto {{ $t('deliveryAddress.deliveryMinPurchase') }}
                span {{ formatPrice(selectedDeliveryMinPurchase) }}
              EatLi.d-flex(v-if="deliveryAddress && deliveryAddress.distanceKm")
                span.me-auto {{ $t('deliveryAddress.addressDistance') }}
                div
                  span.text-error(v-if="!isAddressInRange(deliveryAddress)") {{ $t('deliveryAddress.addressTooFar', { maxDistance: maxDeliveryDistance }) }}
                  span(v-else) {{ deliveryAddress.distanceKm || 0 }} km
              EatLi.d-flex(v-if="deliveryAddress && isAddressInRange(deliveryAddress) && deliveryPrice && deliveryPrice.price")
                span.me-auto {{ $t('general.deliveryPrice') }}
                span + {{ deliveryPrice.isPercentage ? deliveryPrice.price + "%" : formatPrice(deliveryPrice.price) }}

  template(v-slot:float-button v-if="isDeliverySelector && deliveryAddress")  
    EatFloatBtn(
      icon="$point"
      :loading="buttonLoading"
      @click="onSaveDeliveryAddress"
      v-if="!autocompletes?.length"
    ) {{ $t(isDeliverySelector ? 'deliveryAddress.confirmAddress' : 'deliveryAddress.saveAddresses')}}
</template>

<script setup lang="ts">
import AddressSearchBar from "@/views/user/address/components/AddressSearchBar.vue";
import AddressList from "@/views/user/address/components/AddressList.vue";
import { setAppTitle } from "@/store/appTitle";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { onMounted, onUnmounted, ref } from "vue";
import { useAutocompletes, type Autocomplete } from "@/store/delivery/autocompletes";
import { type Address, isAddressComplete, isEqualAddress } from "@/models/Address";
import { useNewAddress } from "@/store/delivery/newAddress";
import { formatPrice } from "@/utils/formatPrice";
import { useDeliveryDistance } from "@/store/delivery/deliveryDistance";
import { goBack } from "@/store/routerHistory";
import { useCart } from "@/store/cart";
import { useDeliveryAddresses } from "@/store/delivery/deliveryAddresses";
import { useDeliveryAddressSelector } from "@/store/delivery/deliveryAddress";
import type { Auth } from "@/plugins/auth";
import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import type { Ref } from "vue";
import type { PropType } from "vue";
import { computed } from "vue";

const props = defineProps({
  baseRoute: {
    type: String as PropType<'order'|'user'>,
    default: 'order'
  }
});

const isDeliverySelector = computed(() => props.baseRoute === 'order');

const auth = inject('auth') as Ref<Auth>;
const i18n = useI18n();
const router = useRouter();
const route = useRoute();

setAppTitle(i18n.t("deliveryAddress.appTitle").toString());

const deliveryImage = new URL('@/assets/images/delivery.jpg', import.meta.url).toString();

const { cart, setCartDeliveryAddress, setInitialCartDeliveryAddress } = useCart();
const { deliveryAddresses, saveNewAddress, deleteAddress } = useDeliveryAddresses(auth.value);
const { isAddressInRange, maxDeliveryDistance } = useDeliveryDistance();
const {
  autocompletes,
  clearAutocompletes,
  clearInputAddress,
  setAutocompleteAsNewAddress
} = useAutocompletes();
const {
  deliveryAddress,
  deliveryPrice,
  selectedDeliveryMinPurchase
} = useDeliveryAddressSelector();
const { newAddress, clearNewAddress } = useNewAddress();

const viewLoading = ref(true);
const buttonLoading = ref(false);

onMounted(async () => {
  viewLoading.value = true;

  if (cart.value?.deliveryAddress) {
    deliveryAddress.value = cart.value?.deliveryAddress;
  }
  clearAutocompletes();
  viewLoading.value = false;
});

onUnmounted(() => { 
  clearAutocompletes();
  clearInputAddress();
});

const onSelectedAutcomplete = async (autocomplete: Autocomplete) => {
  await setAutocompleteAsNewAddress(autocomplete);

  if (newAddress.value && isAddressComplete(newAddress.value) && deliveryAddresses.value) {
    await saveNewAddress(newAddress.value);
    if (isAddressInRange(newAddress.value)) {
      deliveryAddress.value = newAddress.value;
    }

    clearNewAddress();
    clearInputAddress();

    setSnackbar(
      i18n.t("deliveryAddress.snackbar.deliveryAddressConfirmed").toString(),
      TypeSnackbar.SUCCESS
    );
  } else {
    if(!auth.value.check()) router.push({ name: "order.newAddress", query: { validate: "true" } });
    else router.push({ name: `${props.baseRoute}.newAddress`, query: { validate: "true" } });
  }
};

const onChangeDeliveryAddress = async (address: Address) => {
  if (address.distanceKm && address.distanceKm > 0) {
    deliveryAddress.value = address;
  } else {
    setSnackbar(
      i18n.t("deliveryAddress.snackbar.distanceMatrixError") as string,
      TypeSnackbar.ERROR
    );
  }
};

const onSaveDeliveryAddress = () => {
  if (!isAddressInRange(deliveryAddress.value)) {
    return setSnackbar(
      i18n.t("deliveryAddress.snackbar.addressTooFar") as string,
      TypeSnackbar.WARNING
    );
  }

  setCartDeliveryAddress(deliveryAddress.value, deliveryPrice.value);
  setSnackbar(
    i18n.t("deliveryAddress.snackbar.deliveryAddressConfirmed").toString(),
    TypeSnackbar.SUCCESS
  );
  goBack(router, route);
};

const onDeleteAddress = async (index: number) => {
  const oldAddress = await deleteAddress(index);
  if (isEqualAddress(oldAddress, cart.value?.deliveryAddress as Address)) {
    setInitialCartDeliveryAddress();
    if (cart.value?.deliveryAddress) {
      deliveryAddress.value = cart.value?.deliveryAddress;
    }
  }

  setSnackbar(
    i18n.t("deliveryAddress.snackbar.addressRemoved").toString(),
    TypeSnackbar.SUCCESS
  );
};
</script>

<style lang="scss" scoped>
.placeholder-image {
  width: 180px;
}
.placeholder__text {
  max-width: 28ch;
  text-align: center;
}
</style>
