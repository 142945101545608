<template lang="pug">
SuccessView.bg-success
  template(v-slot:icon)
    EatIcon.mb-5.text-white(icon="$successMessage" :size="112")
  template(v-slot:title) {{$t('auth.confirmAccount.title')}}
  template(v-slot:text) {{$t('auth.confirmAccount.text')}}
  template(v-slot:action)
    EatBtn.flex-grow-0.mt-15.bg-white(:to="{ name: 'auth.login' }") {{ $t('auth.confirmAccount.goToLogin') }}
</template>

<script setup lang="ts">
import { confirmAccount } from "@/api/User";
import SuccessView from "@/layout/AuthSuccessView.vue";
import { setAppTitle } from "@/store/appTitle";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const loading = ref(true);
const i18n = useI18n();
setAppTitle(i18n.t("auth.confirmAccount.appTitle").toString());

onMounted(async () => {
  const route = useRoute();
  const router = useRouter();

  const token = route.query.token;
  if (!token) {
    router.replace({ name: "auth.login" });
    return;
  }
  await confirmAccount({ token });
  loading.value = false;
});
</script>
