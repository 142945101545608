import { getFullAddress, getPlacesAutocompletes } from "@/utils/googleUtils";
import { ref } from "vue";
import { useNewAddress } from "./newAddress";

export interface Autocomplete {
  address: string;
  placeId: string;
}

const autocompletes = ref<Autocomplete[]>([]);
const inputAddress = ref("");

export function useAutocompletes() {
  const { newAddress } = useNewAddress();

  const clearInputAddress = () => {
    inputAddress.value = "";
  };

  const getAutocompletes = async (locale: string): Promise<Autocomplete[]> => {
    if (inputAddress.value && inputAddress.value !== "") {
      return await getPlacesAutocompletes(inputAddress.value, locale);
    } else {
      return [];
    }
  };

  const clearAutocompletes = () => {
    autocompletes.value = [];
  };

  const setAutocompleteAsNewAddress = async (autocomplete: Autocomplete) => {
    newAddress.value = await getFullAddress(autocomplete.placeId);
  };

  return {
    inputAddress,
    autocompletes,
    clearAutocompletes,
    clearInputAddress,
    getAutocompletes,
    setAutocompleteAsNewAddress
  };
}
