<template lang="pug">
button.apple-pass__btn.bg-black(:class="{ disabled: !isDeviceIOSAndBrowserSafari }")
  transition(name="fade")
    Loading(v-if="isDownloading" :size="20" :width="3")
    EatImage(v-else 
      :src="src" 
      :height="51" 
      aspectRatio="111/35"
    )        
</template>

<script setup lang="ts">
import { isDeviceIOSAndBrowserSafari } from "@/utils/userAgentData";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

defineProps({
  isDownloading: {
    type: Boolean,
    default: false
  }
});

const i18n = useI18n();
const src = computed(() => {
  if(i18n.locale.value === 'it') {
    return new URL('@/assets/images/pkpass/IT_Add_to_Apple_Wallet_RGB.svg', import.meta.url).toString();
  }
  if(i18n.locale.value === 'en') {
    return new URL('@/assets/images/pkpass/EN_Add_to_Apple_Wallet_RGB.svg', import.meta.url).toString();
  }
  if(i18n.locale.value === 'de') {
    return new URL('@/assets/images/pkpass/DE_Add_to_Apple_Wallet_RGB.svg', import.meta.url).toString();
  }
  if(i18n.locale.value === 'fr') {
    return new URL('@/assets/images/pkpass/FR_Add_to_Apple_Wallet_RGB.svg', import.meta.url).toString();
  }
  if(i18n.locale.value === 'es') {
    return new URL('@/assets/images/pkpass/ES_Add_to_Apple_Wallet_RGB.svg', import.meta.url).toString();
  }
});
</script>

<style lang="scss" scoped>
.apple-pass__btn {
  border-radius: 10px;
  text-decoration: none;
  min-height: 51px;
  aspect-ratio: 111 / 35;
  &.disabled {
    opacity: 0.7;
  }
}
</style>
