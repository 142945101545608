import EatTopBar from "@/layout/partials/EatTopBar.vue";
import Account from "@/views/user/account/Account.vue";
import AccountTopBar from "@/views/user/account/partials/TopBar.vue";
import OrderHistory from "@/views/user/account/history/OrderHistory.vue";
import ReservationHistory from "@/views/user/account/history/ReservationHistory.vue";
import EditProfile from "@/views/user/profile/EditProfile.vue";
import EditPassword from "@/views/user/profile/EditPassword.vue";
import ProfileSettings from "@/views/user/profile/ProfileSettings.vue";
import NewAddress from "@/views/user/address/NewAddress.vue";
import ModifyAddress from "@/views/user/address/ModifyAddress.vue";
import CountrySelector from "@/views/user/address/CountrySelector.vue";
import DeliveryAddress from "@/views/user/address/DeliveryAddress.vue";
import FidelityCards from "@/views/user/account/FidelityCards.vue";
import PromoCodes from "@/views/user/account/PromoCodes.vue";
import Languages from "@/views/user/account/Languages.vue";
import UserMoreDialog from "@/views/user/more/UserMoreDialog.vue";
import type { RouteLocation, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/user",
    name: "user",
    components: {
      topBar: AccountTopBar,
      default: Account,
      more: UserMoreDialog
    },
    meta: { mainView: true, auth: true },
    children: [
      {
        path: "orders",
        name: "user.orders",
        component: OrderHistory,
        meta: {
          auth: true,
          hasGreyBackground: true,
          hideTopBar: true
        }
      },
      {
        path: "reservations",
        name: "user.reservations",
        component: ReservationHistory,
        meta: {
          auth: true,
          hasGreyBackground: true,
          hideTopBar: true
        }
      }
    ]
  },
  {
    path: "/user/profile-settings",
    name: "user.profileSettings",
    components: {
      topBar: EatTopBar,
      default: ProfileSettings,
      more: UserMoreDialog
    },
    meta: {
      auth: true,
      hideNavbar: true,
      showMoreButton: true,
      hasGreyBackground: true,
      showBackButton: true
    }
  },
  {
    path: "/user/profile-settings/edit-profile",
    name: "user.editProfile",
    components: {
      topBar: EatTopBar,
      default: EditProfile
    },
    meta: {
      auth: true,
      hideNavbar: true,
      hideTitle: true,
      showBackButton: true
    }
  },
  {
    path: "/user/edit-password",
    name: "user.editPassword",
    components: {
      topBar: EatTopBar,
      default: EditPassword
    },
    meta: {
      auth: true,
      hideNavbar: true,
      hideTitle: true,
      showBackButton: true
    }
  },
  {
    path: "/user/delivery-address",
    name: "user.deliveryAddress",
    components: {
      topBar: EatTopBar,
      default: DeliveryAddress
    },
    props: { default: { baseRoute: "user" } },
    meta: { backgroundClass: 'bg-primary', hideNavbar: true, showBackButton: true }
  },
  {
    path: "/user/new-address",
    name: "user.newAddress",
    components: {
      topBar: EatTopBar,
      default: NewAddress
    },
    props: { default: (route: RouteLocation) => ({ validate: route.query.validate, baseRoute: "user" }) },
    meta: { hideNavbar: true, hasCloseButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/user/new-address/country",
    name: "user.newAddressCountry",
    components: {
      topBar: EatTopBar,
      default: CountrySelector
    },
    props: { default: { baseRoute: "user" } },
    meta: { hideNavbar: true, showBackButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/user/modify-address/:index",
    name: "user.modifyAddress",
    components: {
      topBar: EatTopBar,
      default: ModifyAddress
    },
    props: { default: (route: RouteLocation) => ({ index: route.params.index, baseRoute: "user" }) },
    meta: { hideNavbar: true, hasCloseButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/user/modify-address/:index/country",
    name: "user.modifyAddressCountry",
    components: {
      topBar: EatTopBar,
      default: CountrySelector
    },
    props: { default: (route: RouteLocation) => ({ index: route.params.index, baseRoute: "user" }) },
    meta: { hideNavbar: true, hasCloseButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/user/fidelity-cards",
    name: "user.fidelityCards",
    components: {
      topBar: EatTopBar,
      default: FidelityCards
    },
    meta: { auth: true, hideNavbar: true, hasCloseButton: true }
  },
  {
    path: "/user/promo-codes",
    name: "user.promoCodes",
    components: {
      topBar: EatTopBar,
      default: PromoCodes
    },
    meta: { auth: true, hideNavbar: true, showBackButton: true, topBarBorderBottom: true }
  },
  {
    path: "/user/change-language",
    name: "user.changeLanguage",
    components: {
      topBar: EatTopBar,
      default: Languages
    },
    meta: { hideNavbar: true, showBackButton: true, topBarBorderBottom: true }
  }
];

export default routes;
