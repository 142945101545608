<template lang="pug">
teleport(to="#age-restriction-dialog")
  EatDialog(v-model="showAgeRestriction" transition="shrink-on-leave")
    div.h-50.bg-white.rounded-t-xxl.elevation-5.d-flex.flex-column
      div.flex-grow-0.px-6.py-3.border-b
        h1.text-h5.font-weight-medium {{ $t('ageRestriction.title') }}
          
      div.flex-grow-0.px-4.py-8.my-auto(v-if="accessDenied")
        p.mb-0.text-uppercase.text-center.font-weight-medium {{$t('ageRestriction.accessDenied')}}
      div.flex-grow-0.px-4.py-8.my-auto(v-else)
        p.my-auto.text-uppercase.text-center.font-weight-medium {{ $t('ageRestriction.confirmYourAge', { threshold }) }}

      div.d-flex.px-4.py-5.gap-3.border-t.mt-auto(v-if="!accessDenied")
        eat-btn.bg-primary.flex-grow-1(@click="confirmAge") {{ $t("ageRestriction.action.confirmAge", { threshold }) }}
        eat-btn.border(@click="leaveSite") {{ $t("ageRestriction.action.noConfirmAge") }}
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import EatDialog from "@/components/modals/EatDialog.vue";
import { SS, SSKeys } from "@/utils/localStorage";

defineProps({
  threshold: {
    type: Number,
    default: 18
  }
});

const showAgeRestriction = ref(false);
const accessDenied = ref(false);

onMounted(() => {
  showAgeRestriction.value = !SS.get(SSKeys.AGE_CONFIRMED) as boolean;
});

const confirmAge = () => {
  SS.set(SSKeys.AGE_CONFIRMED, true);
  showAgeRestriction.value = false;
};

const leaveSite = () => {
  SS.set(SSKeys.AGE_CONFIRMED, false);
  accessDenied.value = true;
  setTimeout(() => {
    location.replace("https://www.google.com");
  }, 3000);
};
</script>
