<template lang="pug">
MoreDialog
  template(v-slot:main)
    EatUl.pt-2 
      EatLi.d-flex.align-center.px-4.py-3(v-for="link in links" @click="link.method")
        EatIcon.mr-5(:icon="link.icon")
        div.align-self-start
          div.text-h6.font-weight-regular {{ $t(link.title) }}
</template>

<script setup lang="ts">
import { onMounted, type Ref, ref, watch } from "vue";
import MoreDialog from "@/layout/partials/MoreDialog.vue";
import { useShowMoreDialog } from "@/store/layout/moreDialog";
import { useRouter, useRoute } from "vue-router";
import { useUser } from "@/store/user";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useApp } from "@/store/app";
import { hasService, Saletype } from "@/models/App";
import useLoginLogout from "@/store/loginLogout";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import { useI18n } from "vue-i18n";

interface Link {
  icon: string;
  title: string;
  routes: Array<string>;
  method?: any;
}

const { app } = useApp();
const showMoreDialog = useShowMoreDialog();
const router = useRouter();
const route = useRoute();
const { user } = useUser();
const auth = inject('auth') as Ref<Auth>;
const i18n = useI18n();

const goToLocation = (location: { name: string, query?: Record<string, string> } | undefined) => {
  if (location) {
    router.push(location);
    showMoreDialog.value = false;
  }
};

const { logout } = useLoginLogout();
const logoutUser = () => {
  logout(auth.value);
  setSnackbar(i18n.t("auth.snackbar.logout").toString(), TypeSnackbar.SUCCESS);
  showMoreDialog.value = false;
  router.replace({ name: "order" });
};

const linksList = [
  {
    icon: "$lock",
    title: "user.more.editPassword",
    routes: ["user", "user.orders", "user.reservations", "user.profileSettings"],
    method: () => goToLocation({ name: "user.editPassword" })
  },
  {
    icon: "$point",
    title: "user.more.editAddress",
    routes: ["user.profileSettings"],
    method: () => goToLocation({ name: "user.deliveryAddress" })
  },
  {
    icon: "$fidelity",
    title: "user.more.fidelityCards",
    routes: ["user.profileSettings"],
    method: () => goToLocation({ name: "user.fidelityCards" })
  },
  {
    icon: "$promotion",
    title: "user.more.promotions",
    routes: ["user.profileSettings"],
    method: () => goToLocation({ name: "user.promoCodes" })
  },
  {
    icon: "$language",
    title: "user.more.changeLanguage",
    routes: ["user", "user.orders", "user.reservations"],
    method: () =>
      goToLocation({ name: "user.changeLanguage", query: { previousRoute: route.name as string } })
  },
  {
    icon: "$info",
    title: "user.more.info",
    routes: ["user", "user.orders", "user.reservations"],
    method: () => {
      if (app.value?.urlPrivacyPolicy) window.open(app.value?.urlPrivacyPolicy, "_self");
    }
  },
  {
    icon: "$logout",
    title: "user.more.logout",
    routes: ["user", "user.orders", "user.reservations"],
    method: () => logoutUser()
  }
];

const links: Ref<Link[]> = ref([]);

const filterLinks = () => {
  links.value = linksList.filter(link => {
    let mustReturn = route.name ? link.routes.includes(route.name as string) : false;
    if (mustReturn) {
      if (link.title === "user.more.editAddress")
        mustReturn = app.value ? hasService(app.value, Saletype.DELIVERY) : false;
      if (link.title === "user.more.editPassword")
        mustReturn = user.value?.facebookId ? false : true;
    }
    return mustReturn;
  });
};

onMounted(() => {
  filterLinks();
});

watch(route, () => {
  filterLinks();
});
</script>
