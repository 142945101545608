<template lang="pug">
div.reservation-history-container
  div.loading-container.d-flex.align-center.pt-10(v-if="loading")
    Loading.text-primary
  RoundedContainer.mt-4.mb-15.overflow-hidden(v-else-if="reservations && reservations.length" :horizontal-padding="false")
    EatUl
      EatLi.d-flex.align-center(v-for="reservation, i in reservations" :key="i")
        div.me-auto
          div.font-weight-medium.text-capitalize {{ fromISO(reservation.date).toFormat('cccc dd LLL, HH:mm', { locale: $i18n.locale }) }}
          div.text--secondary {{ $t('user.reservationHistory.people', { people: reservation.covers }) }}
        div.text-right.mr-1
          div.d-flex.align-center.reservation-badge.rounded-sm.pa-2
            div.reservation-status-color.rounded-circle.me-2(:style="{'background-color': reservationStatusToColor(reservation.status)}")
            span.me-2 {{ $t(`general.reservation.status.${reservation.status}`) }}
  div.text-center.text-h6.font-weight-regular.mt-10(v-else) {{ $t('user.reservationHistory.noReservations') }}
</template>

<script setup lang="ts">
import { getReservationHistory } from "@/api/User";
import Loading from "@/components/utils/Loading.vue";
import { type Reservation, reservationStatusToColor } from "@/models/Order";
import { DateTime } from "luxon";
import { onMounted, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

const loading = ref(true);
const fromISO = DateTime.fromISO;

const reservations: Ref<Reservation[]> = ref([]);
onMounted(async () => {
  loading.value = true;
  reservations.value = await getReservationHistory();
  loading.value = false;
});
</script>

<style lang="scss" scoped>
.reservation-badge {
  border: 1px solid rgba(0, 0, 0, 0.12);
  .reservation-status-color {
    width: 16px;
    height: 16px;
  }
}

.reservation-history-container {
  .loading-container {
    height: 100%;
  }
}
</style>
