<template lang="pug">
transition(name="grow")
  EatDialog(v-model="showMoreDialog")
    div.absolute-blr.max-h-100.bg-white.rounded-t-xxl.d-flex.flex-column.overflow-hidden.elevation-5
      header.flex-grow-0
        slot(name="top")
      main.text-black.flex-grow-1(v-scrollable="true")
        slot(name="main")
      footer.flex-grow-0.bg-white
        slot(name="bottom")
</template>

<script setup lang="ts">
import { useShowMoreDialog } from "@/store/layout/moreDialog";
import EatDialog from "@/components/modals/EatDialog.vue";

defineProps({
  maxHeightPercentage: {
    type: String,
    default: "50%"
  }
});

const showMoreDialog = useShowMoreDialog();
</script>

<style lang="scss">
.grow-enter-active,
.grow-leave-active {
  transform: translateY(0);
  transition: all 0.4s ease-in-out;
}

.grow-enter,
.grow-leave-to {
  transform: translateY(100%);
}
</style>
