import { get as apiGet } from "./API";

export async function get(): Promise<any> {
  return apiGet("/user/fidelity-card-pass", {
    params: { operatingSystem: "ios" },
    requiresAuth: true,
    responseType: "arraybuffer"
  });
}

export async function getReservation(id: string): Promise<any> {
  return apiGet(`/reservations/${id}/pass`, {
    params: { operatingSystem: "ios" },
    responseType: "arraybuffer"
  });
}
