<template lang="pug">
SimpleFormView.bg-white
  template(v-slot:title)
    span {{$t('auth.resetPassword.title')}}
  template(v-slot:text)
    span {{$t('auth.resetPassword.text')}}
  template(v-slot:form)
    form.flex-grow-1.d-flex.flex-column.justify-space-between.px-3(@submit="onSubmit")
      div.flex-grow-1
        EatField(name="password" v-slot="{ value, handleChange }")
          EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lock" :placeholder="$t('form.newPassword.placeholder')")
          EatFieldMessage(name="password" hint="form.hint.required")
        EatField(name="passwordConfirm" v-slot="{ value, handleChange }")          
          EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lockSolid" :placeholder="$t('form.passwordConfirm.placeholder')")
          EatFieldMessage(name="passwordConfirm" hint="form.hint.required")    
      
      EatBtn.flex-grow-0.bg-primary(
        type="submit"
        :loading="isSubmitting"
      ) {{$t('auth.resetPassword.continue')}}
</template>

<script setup lang="ts">
import { resetPassword as userResetPassword } from "@/api/User";
import { setAppTitle } from "@/store/appTitle";
import type { NullRef } from "@/utils";
import { useForm } from "vee-validate";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { object, string } from "yup";

const i18n = useI18n();
setAppTitle(i18n.t("auth.resetPassword.title").toString());

const route = useRoute();
const router = useRouter();

const token: NullRef<string> = ref(null);
onMounted(() => {
  token.value = route.query.token as string | null;
  if (!token.value) {
    router.replace({ name: "auth.login" });
  }
});

const { handleSubmit, isSubmitting } = useForm({
  validationSchema: object({
    password: string().required().label('form.newPassword.fieldName'),
    passwordConfirm: string().required()
      .test('passwords-match', '', function(value, ctx){
        if(this.parent.password === value) return true;
        else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.passwordMatch', params: { label }}) });
      }).label('form.passwordConfirm.fieldName')
  })
});

const resetPassword = async (values: any) => {
  await userResetPassword({
    newPassword: values.password,
    token: token.value
  });
  router.replace({ name: "auth.resetPasswordSuccess" });
};

const onSubmit = handleSubmit(resetPassword)
</script>
