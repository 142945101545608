import type { PaidItem } from "@/store/table";
import type { PaymentMethod, Saletype } from "./App";
import type { PriceVariation } from "./PriceVariation";
import type { ArticleAttributeFieldType, ArticleAttributeValue } from "./Product";
import type { Address } from "./Address";

export enum OrderStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  PROCESSING = "PROCESSING",
  DECLINED = "DECLINED",
  CLOSED = "CLOSED",
  WAITING_PAYMENT = "WAITING_PAYMENT"
}


export interface OrderItemMandatoryComponent {
  productId: string;
  name: string;
  addingPrice: number;
  isDefault: boolean;
}

export interface OrderItemRemovedComponent {
  productId: string;
  name: string;
  removingPrice: number;
  isDefault: boolean;
}

export interface OrderItemAddedComponent {
  productId: string;
  name: string;
  addingPrice: number;
}

export interface OrderItemModularProduct {
  productId: string;
  moduleName: string;
  name: string;
  priceVariation: number;
}

export interface OrderItemsArticleAttribute {
  attributeTypeId: string;
  fieldType: ArticleAttributeFieldType;
  name: string;
  attributeValue: ArticleAttributeValue;
}

export interface OrderItem {
  id: string;
  name: string;
  categoryId?: string;
  productId: string;
  quantity: number;
  paidQuantity?: number;
  images?: string[];
  unitaryBasePrice: number;
  unitaryPrice: number;
  basePrice: number;
  priceVariation: number;
  price: number;
  finalPrice: number;
  finalUnitaryPriceVariation: PriceVariation;
  mandatoryComponents: OrderItemMandatoryComponent[];
  removedComponents: OrderItemRemovedComponent[];
  addedComponents: OrderItemAddedComponent[];
  isModular: boolean;
  choosenProducts?: OrderItemModularProduct[];
  upsellingProducts?:  OrderItemModularProduct[];
  articleAttributes: OrderItemsArticleAttribute[];
  isSubtotal: boolean;
  isMembershipDiscount: boolean;
  serviceChargeId: string;
  isCancelled?: boolean;
}

export interface Order {
  id: string;
  payments: any[];
  qrcode: string;
  saleType: Saletype;
  status: OrderStatus;
  paymentMethod: PaymentMethod;
  finalPrice: number;
  paymentUrl?: string;
  paymentBody?: Record<string, any>;
  hasBeenPaid?: boolean;
  leftToPayAmount?: number;
  isDeposit?: boolean;
  isSplit?: boolean;
  isDutch?: boolean;
  dutchDivider?: number;
  dutchPaying?: number;
  payingOrderItems?: PaidItem[];

  sdkApiUrl?: string;
  easyApiKey?: string;
  paymentId?: string;

  date: string;
  name: string;
  covers: number;
  serviceChargeItem?: OrderItem;
  discountItem?: OrderItem;
  subtotalItem?: OrderItem;
  note: string;
  orderItems: Array<OrderItem>;
  promoCodeDiscountsTotal: number;
  promoCodeId: string;
  deliveryAddress: Address;
}

export function orderStatusToColor(status: OrderStatus) {
  const colors = {
    [OrderStatus.PENDING]: "#F2CC0C",
    [OrderStatus.APPROVED]: "#49CC3F",
    [OrderStatus.PROCESSING]: "#FAA019",
    [OrderStatus.DECLINED]: "#f23a30",
    [OrderStatus.CLOSED]: "#5098E6",
    [OrderStatus.WAITING_PAYMENT]: "#3C7ABE"
  };
  return colors[status];
}

export enum ReservationStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  CLOSED = "CLOSED"
}

export interface Reservation {
  id: string;
  name: string;
  covers: number;
  status: ReservationStatus;
  date: string;
}

export function reservationStatusToColor(status: ReservationStatus) {
  const colors = {
    [ReservationStatus.PENDING]: "#F2CC0C",
    [ReservationStatus.APPROVED]: "#49CC3F",
    [ReservationStatus.DECLINED]: "#f23a30",
    [ReservationStatus.CLOSED]: "#5098E6"
  };
  return colors[status];
}
