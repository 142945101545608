<template lang="pug">
RoundedContainer(:horizontal-padding="false" :title="title")
  EatUl
    EatLi.py-4(v-for="address, i in addressList" :key="i" :class="{ 'bg-selected': isAddressSelected(address) && isDeliverySelector }" @click="onChange(address)")
      div.d-flex.align-center
        button.address-selector__label-container.text-left.flex-grow-1.pe-4
          div.address-selector__label(:class="{ 'text-error': !isAddressInRange(address) }") 
            AddressFormatted(:address="address")
        EatIcon.me-4(size="20px" v-if="address.isDefaultDeliveryAddress" icon="$flagBold").preferred-icon.text-warning.ms-auto 
        button.me-4(@click="onDeleteButtonClick(i)" v-if="!isDeliverySelector")
          EatIcon.text-error(icon="$trash")
        button(@click="onModifyButtonClick(i)")
          EatIcon(:class="{ 'text-muted': !isAddressSelected(address) && isDeliverySelector }" icon="$pencil")
</template>

<script setup lang="ts">
import { type Address, isEqualAddress } from "@/models/Address";
import { useDeliveryAddressSelector } from "@/store/delivery/deliveryAddress";
import { useDeliveryAddresses } from "@/store/delivery/deliveryAddresses";
import { useDeliveryDistance } from "@/store/delivery/deliveryDistance";
import { useNewAddress } from "@/store/delivery/newAddress";
import { computed } from "vue";
import { type PropType } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  baseRoute: {
    type: String as PropType<'order'|'user'>,
    default: 'order'
  },
  addressList: {
    type: Array as PropType<Address[]>,
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["select-delivery-address", "delete-address"]);

const router = useRouter();
const { isAddressInRange } = useDeliveryDistance();
const { newAddress } = useNewAddress();
const { deliveryAddresses } = useDeliveryAddresses();
const { deliveryAddress } = useDeliveryAddressSelector();
const isDeliverySelector = computed(() => props.baseRoute === 'order');

const onChange = (address: Address) => {
  if (isDeliverySelector.value) {
    emit("select-delivery-address", address);
  }
};

const onModifyButtonClick = (index: number) => {
  newAddress.value = { ...deliveryAddresses.value[index] };

  router.push({ name: `${props.baseRoute}.modifyAddress`, params: { index: `${index}` } });
};

const onDeleteButtonClick = (index: number) => {
  emit("delete-address", index);
};

const isAddressSelected = (address: Address) => {
  return deliveryAddress.value && isEqualAddress(address, deliveryAddress.value as Address);
};
</script>

<style lang="scss" scoped>
.active {
  background-color: var(--bgselected) !important;
}
.address-list-element {
  cursor: pointer;
}
</style>
