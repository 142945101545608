import type { PromoCode } from "@/models/PromoCode";
import { onMounted, ref } from "vue";
import { get as getPromoCodes } from "@/api/promoCodes";

export const usePromoCodes = () => {
  const promoCodes = ref<PromoCode[]>([]);
  const loading = ref(true);

  onMounted(async () => {
    try {
      promoCodes.value = await getPromoCodes();
    } finally {
      loading.value = false;
    }
  });

  return { loading, promoCodes };
};
