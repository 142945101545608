<template lang="pug">
SimpleFormView.bg-white
  template(v-slot:title)
    span {{$t('auth.forgotPassword.title')}}
  template(v-slot:text)
    span {{$t('auth.forgotPassword.text')}}
  template(v-slot:form)
    form.flex-grow-1.d-flex.flex-column.justify-space-between.px-3(@submit="onSubmit")
      div.flex-grow-1
        EatField(name="email" v-slot="{ value, handleChange }")
          EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="email" icon="$mail" :fieldName="$t('form.email.fieldName')" :placeholder="$t('form.email.placeholder')")
          EatFieldMessage(name="email" hint="auth.forgotPassword.passwordFieldHint")
        div.mt-5
          EatField(name="g-recaptcha-response" v-slot="{ value, handleChange, errorMessage }")
            EatRecaptcha(:modelValue="value" @update:modelValue="handleChange")
            EatFieldMessage.mt-6(hint="form.hint.required" :errorMessage="errorMessage")

      EatBtn.flex-grow-0.mt-7.bg-primary(
        :loading="isSubmitting"
        type="submit"
      ) {{$t('auth.forgotPassword.continue')}}
</template>

<script setup lang="ts">
import { requestResetPassword } from "@/api/User";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { string } from "yup";

interface PasswordResetForm {
  email: string;
  "g-recaptcha-response": string;
}

const i18n = useI18n();
const router = useRouter();

const { setFieldValue, isSubmitting, handleSubmit } = useForm({
  validationSchema: {
    email: string().required(),
    'g-recaptcha-response': string().required(),
  }
})

const resetPassword = async (values: any) => {
  try {
    await requestResetPassword({
      email: values.email,
      'g-recaptcha-response': values['g-recaptcha-response']
    } as PasswordResetForm);
  } finally {
    setFieldValue('g-recaptcha-response', '');
    setSnackbar(
      i18n.t("auth.snackbar.forgotPassword.emailSent").toString(),
      TypeSnackbar.SUCCESS
    );
    router.push({ name: "auth.login" });
  }
};

const onSubmit = handleSubmit(resetPassword);
</script>

<style></style>
