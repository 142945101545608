<template lang="pug">
EatLi.card__container(:padding="false" @click="openProductDetail")
  div.card__content.d-flex.align-center.bg-white.px-4.py-3(ref="card")
    div.me-auto
      div {{ fromISO(order.date).toFormat('dd LLL, HH:mm', { locale: $i18n.locale }) }}
      div.text--secondary {{ $t(`general.saletype.${order.saleType}`)}}
    div
      div.d-flex.align-center.order-badge.rounded-sm.pa-2
        div.order-status-color.rounded-circle.me-2(:style="{'background-color': orderStatusToColor(order.status)}") 
        span.me-2 {{ $t(`general.order.status.${order.status}`) }}
</template>

<script setup lang="ts">
import { type Order, orderStatusToColor } from "@/models/Order";
import { type PropType, ref } from "vue";
import { DateTime } from "luxon";
import useOrderDetail from "@/store/orderHistory/orderDetail";

const props = defineProps({
  order: {
    type: Object as PropType<Order>,
    required: true
  }
});
  
const { fromISO } = DateTime;

const card = ref<HTMLElement>();
const openProductDetail = () => {
  const { setOrder } = useOrderDetail();
  setOrder(props.order);
};
</script>

<style lang="scss" scoped>
.card__container {
  height: 73px;
}

.order-badge {
  border: 1px solid rgba(0, 0, 0, 0.12);
  .order-status-color {
    width: 16px;
    height: 16px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
