import type { Address } from "@/models/Address";
import { PaymentMethod, Saletype } from "@/models/App";
import type { Order } from "@/models/Order";
import type { AffiliationDiscount, PriceVariation } from "@/models/PriceVariation";
import { delete_ as apiDelete, get as apiGet, post as apiPost, put as apiPut } from "./API";
import type { AppliedPromoCode } from "@/models/PromoCode";

export async function get(orderId: string): Promise<Order> {
  return apiGet(`orders/${orderId}`, { requiresAuth: true });
}

export async function getUnauthenticated(orderId: string): Promise<Order> {
  return apiGet(`orders/${orderId}/unauthenticated`);
}

export interface CreateOrderItemParams {
  productId: string;
  quantity: number;
  removedComponents: string[];
  addedComponents: string[];
  modules: Array<{
    name: string;
    choosenProducts: string[];
    upsellingProducts: string[];
  }>;
  articleAttributes: Array<{
    attributeTypeId: string;
    attributeValue: string | null | boolean | number;
  }>;
  finalUnitaryPriceVariation?: PriceVariation;
}

export interface CreateOrderSubtotalParams {
  isSubtotal: boolean;
  finalUnitaryPriceVariation?: PriceVariation;
}

export interface CreateOrderParams {
  name: string | undefined;
  covers: number | undefined;
  saleType: Saletype;
  priceListId: string | null;
  note: string | null;
  date: string;
  deliveryAddress: Address | null;
  orderItems: (CreateOrderItemParams|CreateOrderSubtotalParams)[],
  paymentMethod: PaymentMethod;
  customFields?:
    | {
        name: string;
        label: string;
        value: string | number;
      }[]
    | null;
  customerRequests: {
    cutlery: boolean;
  };
  invoiceData?: {
    businessName: string;
    address: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    province: string;
    vatCode?: string;
    fiscalCode?: string;
    invoicingEndpoint: string;
  };
}

export interface OrderPromoCodeResponse {
  promoCodeId: string;
  orderTotal: number;
  finalUnitaryPriceVariation: PriceVariation | null;
  orderItems: OrderPromoCodeItemRequest[];
  discountItem: AffiliationDiscount;
  serviceChargeItem: OrderPromoCodeItemRequest;
  subtotalItem: OrderPromoCodeItemRequest;
  promoCodes: AppliedPromoCode[];
}

export interface OrderPromoCodeItemRequest {
  productId: string;
  finalUnitaryPriceVariation: PriceVariation | null; 
  unitaryPrice: number,
  price: number,
  priceVariation: number,
  finalPrice: number,
  quantity: number;
  isSubtotal: boolean;
  promoCode: AppliedPromoCode;
}

export async function addPromoCodeToOrder(
  params: CreateOrderParams,
  promoCode: string
): Promise<OrderPromoCodeResponse> {
  return apiPost(`orders/${promoCode}/promo-codes`, params, { requiresAuth: true });
}

export async function addPromoCodeToOrderUnauthenticated(
  params: CreateOrderParams,
  promoCode: string
): Promise<OrderPromoCodeResponse> {
  return apiPost(`orders/${promoCode}/promo-codes/unauthenticated`, params);
}

export async function getMembershipDiscount(
  params: CreateOrderParams,
): Promise<OrderPromoCodeResponse> {
  return apiPost(`orders/affiliations/discount`, params, { requiresAuth: true });
}

export interface CreateOrderResponse {
  paymentGateway?: PaymentMethod,
  id: string;
  qrcode: string;
  paymentId?: string;
  paymentUrl?: string;
  paymentBody?: Record<string, any>;
  payPalOrderId?: string;
  easyApiKey?: string;
  sdkApiUrl?: string;
}

export async function createOrder(params: CreateOrderParams): Promise<CreateOrderResponse> {
  return apiPost("orders", params, { requiresAuth: true });
}

export async function createOrderUnauthenticated(
  params: CreateOrderParams
): Promise<CreateOrderResponse> {
  return apiPost("orders/unauthenticated", params);
}

export async function getPostePaymentURL(orderId: string) {
  return apiGet(`orders/${orderId}/poste-payment-url`, { requiresAuth: true });
}

interface ChangePaymentMethodData {
  paymentMethod: PaymentMethod;
}
export async function changePaymentMethod(orderId: string, data: ChangePaymentMethodData) {
  return apiPut(`orders/${orderId}/payment-method`, data, { requiresAuth: true });
}

export async function delete_(orderId: string) {
  return apiDelete(`orders/${orderId}`, { requiresAuth: true });
}
