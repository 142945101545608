<template lang="pug">
EatView.container.bg-white(:isScrollable="true")
  form.d-flex.flex-column.mt-6(tag="form" @submit="onSubmit")
    EatField(name="city" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.city.placeholder")
      EatFieldMessage(name="city" hint="form.hint.required")
    EatField(name="zipCode" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.zipCode.placeholder")
      EatFieldMessage(name="zipCode" hint="form.hint.required")
    EatField(name="address" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.address.placeholder")
      EatFieldMessage(name="address" hint="form.hint.required")
    EatField(name="streetNumber" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.streetNumber.placeholder")
      EatFieldMessage(name="streetNumber" hint="form.hint.required")
    RouterLink.mb-6(:to="{ name: `${baseRoute}.newAddressCountry` }")
      EatField(name="nation" v-slot="{ value }")
        EatTextInput(readonly :modelValue="value" placeholder="form.address.nation.placeholder")
        EatFieldMessage(name="nation" hint="form.hint.required")

    EatBtn.mt-15.bg-primary(
      type="submit"
      :loading="isSubmitting"
    ) {{$t('deliveryAddress.newAddress.saveAddress')}}
    EatBtn.mt-6.text-primary(
      :to="{ name: `${baseRoute}.deliveryAddress` }"
    ) {{$t('general.action.cancel')}} 
</template>

<script setup lang="ts">
import { onUnmounted, ref } from "vue";
import { useNewAddress } from "@/store/delivery/newAddress";
import { isAddressComplete, type Address } from "@/models/Address";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useDeliveryAddressSelector } from "@/store/delivery/deliveryAddress";
import { useCart } from "@/store/cart";
import { useDeliveryAddresses } from "@/store/delivery/deliveryAddresses";
import { setAppTitle } from "@/store/appTitle";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter, RouterLink } from "vue-router";
import type { Ref } from "vue";
import { useForm } from "vee-validate";
import { string } from 'yup';
import type { PropType } from "vue";
import { computed } from "vue";

const i18n = useI18n();
setAppTitle(i18n.t("deliveryAddress.newAddress.appTitle").toString());

const props = defineProps({
  baseRoute: {
    type: String as PropType<'order'|'user'>,
    default: 'order'
  }
})

const auth = inject('auth') as Ref<Auth>;
const route = useRoute();
const router = useRouter();
const isDeliverySelector = computed(() => props.baseRoute === 'order');

const { cart, setInitialCartDeliveryAddress } = useCart();
const { deliveryAddresses, saveNewAddress } = useDeliveryAddresses(auth.value);
const { deliveryAddress } = useDeliveryAddressSelector();
const { newAddress, clearNewAddress } = useNewAddress();

const { isSubmitting, handleSubmit } = useForm({
  validateOnMount: !!route.query.validate,
  validationSchema: {
    city: string().required().label('form.address.city.fieldName'),
    zipCode: string().required().label('form.address.zipCode.fieldName'),
    address: string().required().label('form.address.address.fieldName'),
    streetNumber: string().required().label('form.address.streetNumber.fieldName'),
    nation: string().required().label('form.address.nation.fieldName')
  },
  initialValues: {
    city: newAddress.value.city,
    zipCode: newAddress.value.zipCode,
    address: newAddress.value.address,
    streetNumber: newAddress.value.streetNumber,
    nation: newAddress.value.nation || 'IT'
  }
});

onUnmounted(() => {
  clearNewAddress();
});

const onValidSubmit = async (values: any) => {
  const newAddress = values as Address;
  if (isAddressComplete(newAddress) && deliveryAddresses.value) {
    await saveNewAddress(newAddress);
    if (cart.value?.deliveryAddress) {
      deliveryAddress.value = cart.value?.deliveryAddress;
    }

    if (isDeliverySelector.value) {
      setInitialCartDeliveryAddress();
    }

    setSnackbar(
      i18n.t("deliveryAddress.snackbar.newDeliveryAddressSaved").toString(),
      TypeSnackbar.SUCCESS
    );
    clearNewAddress();
    if(!auth.value.check()) router.push({ name: "order.deliveryAddress" });
    else router.push({ name: `${props.baseRoute}.deliveryAddress` });
  }
};

const onSubmit = handleSubmit(onValidSubmit);
</script>
