<template lang="pug">
SuccessView.bg-success
  template(v-slot:icon)
    div.icon.d-flex.justify-center.align-center
      EatIcon.mb-5.text-white(icon="$successMessage" :size="112") 
      //-- lottie(:animationData="animationData")
  template(v-slot:title) {{$t('auth.registerSuccess.title')}}
  template(v-slot:text) {{$t('auth.registerSuccess.text')}}
  template(v-slot:action)
    p.text-caption.text-white(style="max-width: 46ch") {{$t('auth.registerSuccess.checkSpam')}}
    EatBtn.flex-grow-0.mt-15.bg-white(:to="{ name: 'auth.login' }") {{ $t('auth.registerSuccess.goToLogin') }}
</template>

<script setup lang="ts">
import Lottie from "@/components/utils/Lottie.vue";
import SuccessView from "@/layout/AuthSuccessView.vue";

import { setAppTitle } from "@/store/appTitle";
import { useI18n } from "vue-i18n";
/* import animationData from "@/assets/animations/registerSuccess.json"; */

const i18n = useI18n();
setAppTitle(i18n.t("auth.registerSuccess.appTitle").toString());
</script>

<style lang="scss" scoped>
.icon {
  height: 12rem;
  width: 12rem;
}
</style>
