<template lang="pug">
EatUl
  EatLi.d-flex.flex-column(v-for="item, i in items" :key="i")
    div.order-product-card
      EatImage.card__image.rounded-sm(
        :src="getImageSrc(item)"
        :lazyLoading="true"
      )
      div.px-3.text-h6.flex-grow-1 {{ item.name }}
      div.card__controls.d-flex.flex-shrink-0.justify-space-between
        div x{{ item.quantity }}
        ItemPrice.pl-2(
          v-if="item.finalUnitaryPriceVariation"
          :isPercentage="item.finalUnitaryPriceVariation.isPercentage"
          :variation="item.finalUnitaryPriceVariation.variation", 
          :price="item.price", 
          :finalPrice="item.finalPrice"
        )  
        Price(v-else :price="item.finalPrice")    
    ItemConfigurationSummary(:item="item")
</template>

<script setup lang="ts">
import { useApp } from "@/store/app";
import type { PropType } from "vue";
import ItemConfigurationSummary from "@/components/ItemConfigurationSummary.vue";
import ItemPrice from "@/components/utils/ItemPrice.vue";
import Price from "@/components/utils/Price.vue";
import type { OrderItem } from "@/models/Order";

defineProps({
  items: {
    type: Array as PropType<OrderItem[]>,
    required: true
  }
});

const { location } = useApp();

const getImageSrc = (item: OrderItem) => {
  return item.images && item.images.length ? item.images[0] : location.value?.logoUrl || "";
};

const configurationModulesToString = (item: OrderItem) => {
  let mustReturn = "";
  if (item.choosenProducts) {
    item.choosenProducts.forEach(product => {
      mustReturn += mustReturn ? `, ${product.name}` : product.name;
    });
  }
  if (item.upsellingProducts) {
    item.upsellingProducts.forEach(product => {
      mustReturn += mustReturn ? `, ${product.name}` : product.name;
    });
  }
  return mustReturn;
};
</script>

<style lang="scss" scoped>
$image-height: 76px;
.order-product-card {
  display: flex;
  align-items: center;
  .card__image {
    width: $image-height;
    flex-shrink: 0;
  }
  .card__controls {
    width: $image-height;
    text-align: end;
  }
}

.components__container {
  flex-wrap: wrap;
  gap: 4px;
  .outlined--cart-chips {
    border: 1px solid var(--border-clr--type-a);
    color: var(--border-clr--type-a);
  }
}
</style>
