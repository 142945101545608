import EatTopBar from "@/layout/partials/EatTopBar.vue";
import Auth from "@/views/auth/Auth.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ConfirmAccount from "@/views/auth/success/ConfirmAccount.vue";
import RegisterSuccess from "@/views/auth/success/RegisterSuccess.vue";
import ResetPasswordSuccess from "@/views/auth/success/ResetPasswordSuccess.vue";
import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/register",
    redirect: { name: "auth.register" }
  },
  {
    path: "/login",
    redirect: { name: "auth.login" }
  },
  {
    path: "/auth",
    name: "auth",
    components: {
      topBar: EatTopBar,
      default: Auth
    },
    meta: { mainView: true },
    children: [
      {
        path: "login",
        name: "auth.login",
        component: Login,
        meta: {
          hideNavbar: true,
          tabs: true,
          isTabRoute: true,
          hasCloseButton: true
        }
      },
      {
        path: "register",
        name: "auth.register",
        component: Register,
        meta: {
          hideNavbar: true,
          tabs: true,
          isTabRoute: true,
          hasCloseButton: true
        }
      }
    ]
  },
  {
    path: "/forgot-password",
    name: "auth.forgotPassword",
    components: {
      topBar: EatTopBar,
      default: ForgotPassword
    },
    meta: {
      hideNavbar: true,
      hideTitle: true,
      showBackButton: true
    }
  },
  {
    path: "/reset-password",
    name: "auth.resetPassword",
    components: {
      topBar: EatTopBar,
      default: ResetPassword
    },
    meta: {
      hideNavbar: true,
      hideTitle: true,
      showBackButton: true
    }
  },
  {
    path: "/reset-password-success",
    name: "auth.resetPasswordSuccess",
    components: {
      default: ResetPasswordSuccess
    },
    meta: {
      hideNavbar: true,
      hideTitle: true,
      hasCloseButton: true,
      backgroundClass: 'bg-success'
    }
  },
  {
    path: "/register-success",
    name: "auth.registerSuccess",
    components: {
      default: RegisterSuccess
    },
    meta: {
      hideNavbar: true,
      hideTitle: true,
      hasCloseButton: true,
      backgroundClass: 'bg-success'
    }
  },
  {
    path: "/conferma-account",
    name: "auth.confirmAccount",
    components: {
      default: ConfirmAccount,
    },
    meta: {
      hideNavbar: true,
      hideTitle: true,
      backgroundClass: 'bg-success'
    }
  }
];

export default routes;
