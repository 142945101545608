<template lang="pug">
EatView.bg-neutral-a
  div.border-b
    div.bg-white.py-3.px-5
      EatSearchBar.elevation-1(v-model="input" icon="$search")
  EatUl(v-scrollable="true")
    EatLi.px-5(v-for="country in countries" :key="country.code" borderBottom @click="onClick(country)")
      div {{ country.name }}
</template>

<script setup lang="ts">
import { type Ref, ref, watch } from "vue";
import EatSearchBar from "@/components/utils/EatSearchBar.vue";
import countriesList from "@/assets/countries.json";
import { setAppTitle } from "@/store/appTitle";
import { useNewAddress } from "@/store/delivery/newAddress";
import { goBack } from "@/store/routerHistory";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";

interface Country {
  name: string;
  code: string;
}

const props = defineProps({
  index: {
    type: String
  }
});

const router = useRouter();
const i18n = useI18n();
const countries: Ref<Country[]> = ref(countriesList);
const { newAddress } = useNewAddress();
const input = ref<string>("");
const route = useRoute();
const auth = inject('auth') as Ref<Auth>;
setAppTitle(i18n.t("deliveryAddress.countrySelector.appTitle").toString());
const isDeliverySelector = ref(!!route.meta.isDeliverySelector);

watch(input, () => {
  countries.value = countriesList.filter(country =>
    country.name.toLowerCase().includes(input.value.toLowerCase())
  );
});

const onClick = (country: Country) => {
  if (newAddress.value) newAddress.value.nation = country.code;
  if (props.index)
    router.push({
      name:
        isDeliverySelector.value || !auth.value.check()
          ? "order.modifyAddress"
          : "user.modifyAddress",
      params: { id: props.index }
    });
  else goBack(router, route);
};
</script>
