<template lang="pug">
EatView.bg-neutral-a
  div.bg-neutral-a.pt-2  
    EatUl(v-if="app")
      EatLi.language(v-for="locale, i in app.availableLanguages" :key="i" @click="onClick(locale)") {{ locale.toUpperCase() }}
</template>

<script setup lang="ts">
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { onMounted } from "vue";
import { setNewLanguage } from "@/utils/localization";
import { useRouterHistory } from "@/store/routerHistory";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { app } = useApp();
const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const routerHistory = useRouterHistory();
setAppTitle(i18n.t("user.languages.appTitle").toString());

onMounted(() => {
  if (routerHistory.value.length <= 1) {
    router.push({ name: (route.query.previousRoute as string) || "order" });
  }
});

const onClick = (locale: string) => {
  setNewLanguage(locale);
};
</script>

<style lang="scss" scoped>
.language {
  cursor: pointer;
}
</style>
