<template lang="pug">
div.bg-white.container.flex-grow-1
  div.pt-6
    FacebookBtn(login)
    div.d-flex.flex-col.mb-6.mt-8.mx-3.justify-center
      span {{ $t('auth.login.orEnterTheData') }}
    form(@submit="onSubmit")
      EatField(name="email" v-slot="{ value, handleChange }")
        EatTextInput(type="email"  :modelValue="value" @update:modelValue="handleChange" icon="$mail" placeholder="form.email.placeholder")
        EatFieldMessage(name="email")
      EatField(name="password" v-slot="{ value, handleChange }")
        EatTextInput(type="password" :modelValue="value" @update:modelValue="handleChange" icon="$lock" placeholder="form.password.placeholder")
        EatFieldMessage(name="email")
      div.text-center.mb-6
        router-link(:to="{name: 'auth.forgotPassword'}")
          span.text-decoration-underline.text-black {{ $t('auth.login.forgotPassword') }}
      EatBtn.bg-primary(type="submit" :loading="isSubmitting") {{ $t('auth.login.login') }} 
</template>

<script setup lang="ts">
import FacebookBtn from "@/components/buttons/FacebookBtn.vue";
import { Auth, getClientId } from "@/plugins/auth";
import { useApp } from "@/store/app";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { setAppTitle } from "@/store/appTitle";
import { useCart } from "@/store/cart";
import { initData } from "@/store/init/initData";
import { useDeliveryAddresses } from "@/store/delivery/deliveryAddresses";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { inject } from "vue";
import type { Ref } from "vue";
import { useForm } from "vee-validate";
import { string } from "yup";

interface LoginForm {
  email: string;
  password: string;
}

const i18n = useI18n();
setAppTitle(i18n.t("auth.login.appTitle").toString());

const auth = inject('auth') as Ref<Auth>;
const router = useRouter();
const route = useRoute();
const { cart, cartPrefix } = useCart();
const { onLoginGetUserDeliveryAddresses } = useDeliveryAddresses();

const { handleSubmit, setFieldValue, isSubmitting } = useForm({
  validationSchema: {
    email: string().email().required().label('form.email.fieldName'),
    password: string().required().label('form.password.fieldName')
  }
});

const login = async (values: any) => {
  const { app } = useApp();
  if (!app.value) throw new Error("Unexpected Error");

  let prefix = getClientId(location);
  if (app.value.channel) {
    prefix = `app_user@${app.value.channel}`;
  }

  const f = values as LoginForm;
  try {
    await auth.value.login(`${prefix}:${f.email}`, f.password);

    await onLoginGetUserDeliveryAddresses();
    if (cartPrefix.value && cart.value?.saletype) await initData(cart.value.saletype, auth.value);

    setSnackbar(i18n.t("auth.snackbar.loginSuccessful").toString(), TypeSnackbar.SUCCESS);
    if (route.query.afterLogin) {
      const afterLogin = route.query.afterLogin as string;
      router.replace({ name: afterLogin });
    } else {
      router.replace({ name: "order" });
    }
  } finally {
    setFieldValue('password', '');
  }
};

const onSubmit = handleSubmit(login)
</script>