<template lang="pug">
FullPageDialog(v-model="showDialog")
  template(v-slot:header)
    div.flex-grow-1.d-flex.py-2.px-4.gap-4.align-center(v-scrollable="false" v-if="order")
      EatIconBtn(icon="$chevronDown" :size="18" @click="closeOrderDetail")  
      div.text-h6.font-weight-medium {{ $t("user.orderDetail.title") }}
  template(v-slot:main)
    div.flex-grow-1.d-flex.flex-column(v-scrollable="false" v-if="order")
      div.bg-neutral-a.py-1.flex-grow-1(v-scrollable="true")
        div.rounded-b-xxl.bg-white.pb-5
          OrderProductsList.overflow-hidden(:items="items")
          EatUl   
            EatLi(v-if="subtotalItem") 
              div.d-flex.align-center
                div.me-auto {{ subtotalItem.name === "no_name" ? "Arrotondamento" : subtotalItem.name }}
                SubTotalPrice(
                  :isPercentage="subtotalItem.finalUnitaryPriceVariation.isPercentage"
                  :variation="subtotalItem.finalUnitaryPriceVariation.variation"
                  :priceVariation="subtotalItem.priceVariation"
                )
            EatLi(v-if="discountItem") 
              div.d-flex.align-center
                div.me-auto {{ discountItem.name }}
                Price(:price="discountItem.finalPrice")    

            EatLi.d-flex(v-if="order.saleType === Saletype.DELIVERY && serviceCharge")
              div.me-auto {{ $t('general.deliveryPrice') }}
              div + {{ formatPrice(serviceCharge) }}
                
            EatLi(v-if="order.saleType === Saletype.DELIVERY && order.deliveryAddress")
              div.d-flex.align-center
                div.me-auto {{ $t("user.orderDetail.deliveryAddress") }}
                AddressFormatted.text-end(:address="order.deliveryAddress")

        RoundedContainer.mt-1
          div.qrcode-container.px-10
            div.qrcode(v-html="order.qrcode")

        RoundedContainer.mt-1(v-if="order.status == OrderStatus.WAITING_PAYMENT")
          EatBtn.mt-4.mb-3(
            :border="true"
            color="text-black"
            v-if="order.paymentMethod == PaymentMethod.POSTE_VIRTUAL_POS"
            @click="retryPayment()"
          ) {{$t('user.orderDetail.action.retryPayment')}}
          EatBtn.mb-3(
            :border="true"
            color="text-black"
            v-if="app?.services[order.saleType].paymentMethods.includes(PaymentMethod.CASH_ON_DELIVERY) && order.paymentMethod != PaymentMethod.CASH_ON_DELIVERY"
            @click="changePaymentMethod(PaymentMethod.CASH_ON_DELIVERY)"
          ) {{$t('user.orderDetail.action.cashOnDelivery')}}
          EatBtn.mb-4.bg-error(
            background="error"
            color="text-white"
            @click="deleteOrder()"
          ) {{$t('user.orderDetail.action.cancelOrder')}}  
          
  template(v-slot:footer)
      EatCta.flex-shrink-0(v-if="order && activeSaletypes.includes(order.saleType)")
        EatCtaButton(@click="onReorder" :primary="true") {{ $t("user.orderDetail.orderAgain")}}
      
      div.border-t.pa-4.d-flex.align-center.justify-space-between.bg-white(v-if="order")
        div {{ $t(getPaymentMethodTranslationKey(order.paymentMethod)) }}
        div.d-flex 
          div.mr-2 {{ $t("general.total") }}
          Price(:price="order.finalPrice")
</template>

<script setup lang="ts">
import FullPageDialog from "@/layout/partials/FullPageDialog.vue";
import Price from "@/components/utils/Price.vue";
import OrderProductsList from "@/components/OrderProductsList.vue";
import SubTotalPrice from "@/components/utils/SubTotalPrice.vue";
import {
  changePaymentMethod as orderChangePaymentMethod,
  delete_,
  getPostePaymentURL
} from "@/api/Order";
import { OrderStatus } from "@/models/Order";
import { useApp } from "@/store/app";
import { formatPrice } from "@/utils/formatPrice";
import { computed } from "vue";
import { PaymentMethod, Saletype } from "@/models/App";
import useOrderDetail from "@/store/orderHistory/orderDetail";
import { useCart } from "@/store/cart";
import { useMenu } from "@/store/menu";
import { useRestoreOrderCart } from "@/store/orderHistory/restoreOrderCart";
import { initData } from "@/store/init/initData";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import { useRouter } from "vue-router";
import type { Ref } from "vue";

const auth = inject('auth') as Ref<Auth>;
const { app, getPaymentMethodTranslationKey, activeSaletypes } = useApp();
const { clearCart, initCart, cartPrefix } = useCart();

const { order } = useOrderDetail();
const showDialog = computed(() => !!order.value);

const items = computed(() => {
  return order.value?.orderItems.filter(item => item.productId) || [];
});
const serviceCharge = computed(() => {
  return order.value?.orderItems.find(item => item.serviceChargeId)?.finalPrice || 0;
});
const subtotalItem = computed(() => order.value?.subtotalItem);
const discountItem = computed(() => order.value?.discountItem);

const closeOrderDetail = () => order.value = undefined;

const retryPayment = async () => {
  if (order.value?.paymentMethod == PaymentMethod.POSTE_VIRTUAL_POS) {
    const url = (await getPostePaymentURL(order.value.id)).paymentUrl;
    window.location.assign(url);
  }
};

const changePaymentMethod = async (method: PaymentMethod) => {
  if (!order.value) return;
  await orderChangePaymentMethod(order.value.id, {
    paymentMethod: method
  });
  window.location.reload();
};

const deleteOrder = async () => {
  if (!order.value) return;
  await delete_(order.value.id);
  window.location.reload();
};

const router = useRouter();
const onReorder = async () => {
  const items = order.value?.orderItems;
  const saletype = order.value?.saleType;
  const { categories, fetchMenu } = useMenu(saletype);
  await fetchMenu();

  if (items && saletype && cartPrefix.value) {
    clearCart();
    initCart({ saletype, prefix: cartPrefix.value });
    await initData(saletype, auth.value);
    closeOrderDetail();

    if (categories.value) {
      const { restoreCartItemsFromOrderItems } = useRestoreOrderCart();
      restoreCartItemsFromOrderItems(items, categories.value);
    }

    setTimeout(() => router.push({ name: "cart" }), 1000);
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
