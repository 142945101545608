<template lang="pug">
button.rounded-xl.facebook-btn.px-5(type="button" @click="facebookLogin" :disabled="loadingLogin")
  EatIcon.facebook-icon(icon="$facebook" size="24").icon.text-white
  div.m-auto.text-white {{ $t("auth.facebookBtn.text")}}
</template>

<script setup lang="ts">
import { facebook as validateFacebook } from "@/api/User";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ref, inject } from "vue";
import type { Auth } from "@/plugins/auth";
import type { Ref } from "vue";

const props = defineProps({
  login: {
    type: Boolean,
    default: false
  }
});
  
const i18n = useI18n();
const loadingLogin = ref(false);
const auth = inject('auth') as Ref<Auth>;
const router = useRouter();
const route = useRoute();

const facebookLogin = () => {
  loadingLogin.value = true;
  const onLogin = async (response: any) => {
    if (response.authResponse) {
        const facebookResponse = await validateFacebook({
          accessToken: response.authResponse.accessToken
        });
        try {
          await auth.value.login(facebookResponse.username, facebookResponse.password);
          setSnackbar(i18n.t("auth.snackbar.loginSuccessful").toString(), TypeSnackbar.SUCCESS);
          if (props.login) {
            if (route.query.afterLogin) {
              const afterLogin = route.query.afterLogin as string;
              router.replace({ name: afterLogin });
            } else {
              router.replace({ name: "location" });
            }
          }
        } finally {
          loadingLogin.value = false;
        }
      } else {
        loadingLogin.value = false;
        throw new Error(i18n.t("auth.snackbar.facebookLoginError").toString());
      }
  }
  // @ts-ignore
  FB.login(
    // @ts-ignore
    response =>  {
      onLogin(response);
    },
    { scope: "public_profile,email" }
  );
};
</script>

<style lang="scss" scoped>
.facebook-btn {
  display: block;
  background-color: #3b5998;
  position: relative;
  height: 44px;
  width: 100%;
  .facebook-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
