<template lang="pug">
div.order-history-container.pb-5
  div.loading-container.d-flex.align-center.pt-10(v-if="loading")
    Loading.text-primary
  RoundedContainer.mt-4.mb-15.bg-white.overflow-hidden(v-else-if="orders && orders.length" :horizontal-padding="false")
    EatUl
      OrderCard.cursor-pointer(v-for="order, i in orders" :key="i" :order="order")
  div.text-center.text-h6.font-weight-regular.mt-10(v-else) {{ $t('user.orderHistory.noOrders') }}

  OrderDetail
</template>

<script setup lang="ts">
import { getOrderHistory } from "@/api/User";
import OrderDetail from "@/views/user/account/history/components/OrderDetail.vue";
import OrderCard from "@/views/user/account/history/components/OrderCard.vue";
import Loading from "@/components/utils/Loading.vue";
import { type Order } from "@/models/Order";
import { computed, onMounted, ref, type Ref } from "vue";

const loading = ref(true);

const canShare = computed(() => {
  // @ts-ignore
  return navigator.canShare;
});

const onShareClick = () => {
  console.log("Da implementare Sharing del QrCode");
};

const orders: Ref<Order[]> = ref([]);
onMounted(async () => {
  loading.value = true;
  orders.value = await getOrderHistory();
  loading.value = false;
});
</script>

<style lang="scss" scoped>
.order-badge {
  border: 1px solid rgba(0, 0, 0, 0.12);
  .order-status-color {
    width: 16px;
    height: 16px;
  }
}
.order-history-container {
  .loading-container {
    height: 100%;
  }
}
</style>
