<template lang="pug">
EatView.container.bg-white(:isScrollable="true" :loading="loading")
  teleport(to="#topBarAction")
    div.d-flex.align-center
      EatIconBtn.me-6.text-error(icon="$trash" @click="onDeleteAddress")
      EatField.d-inline(name="isDefaultDeliveryAddress" v-slot="{ value, handleChange }")
        EatToggleButton(:modelValue="value" @update:modelValue="handleChange")
          EatToggleIcon(:on="value" onIcon="$flagBold" offIcon="$flag" :class="{ 'text-warning': value }")
        
  form.py-5(@submit="onSubmit")
    EatField(name="city" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.city.placeholder")
      EatFieldMessage(name="city" hint="form.hint.required")
    EatField(name="zipCode" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.zipCode.placeholder")
      EatFieldMessage(name="zipCode" hint="form.hint.required")
    EatField(name="address" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.address.placeholder")
      EatFieldMessage(name="address" hint="form.hint.required")
    EatField(name="streetNumber" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.streetNumber.placeholder")
      EatFieldMessage(name="streetNumber" hint="form.hint.required")
    div(@click.prevent="$router.push({ name: `${baseRoute}.modifyAddressCountry`, params: { index: index } })")
      EatField(name="nation" v-slot="{ value }")
        EatTextInput(readonly :modelValue="value" placeholder="form.address.nation.placeholder")
        EatFieldMessage(name="nation" hint="form.hint.required")

    EatBtn.mt-15.flex-grow-0.bg-primary(
      type="submit"
      :loading="isSubmitting"
    ) {{$t('deliveryAddress.modifyAddress.saveChanges')}}
    
    EatBtn.mt-7.text-primary(
      :to="{ name: `${baseRoute}.deliveryAddress` }"
    ) {{$t('general.action.cancel')}} 
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, inject, nextTick } from "vue";
import { goBack } from "@/store/routerHistory";
import { setErrorSnackbar, setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { type Address, isAddressComplete, isEqualAddress } from "@/models/Address";
import { useDeliveryAddressSelector } from "@/store/delivery/deliveryAddress";
import { useCart } from "@/store/cart";
import { useDeliveryAddresses } from "@/store/delivery/deliveryAddresses";
import { setAppTitle } from "@/store/appTitle";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import type { Auth } from "@/plugins/auth";
import { useForm } from "vee-validate";
import { string } from "yup";
import type { PropType } from "vue";

const props = defineProps({
  index: {
    type: String,
    required: false
  },
  baseRoute: {
    type: String as PropType<'order'|'user'>,
    default: 'order'
  }
});

const auth = inject('auth') as Ref<Auth>;
const router = useRouter();
const route = useRoute();
const i18n = useI18n();
const { cart, setInitialCartDeliveryAddress } = useCart();
const { deliveryAddress } = useDeliveryAddressSelector();
const { deliveryAddresses, modifyAddress, deleteAddress } = useDeliveryAddresses(auth.value);

setAppTitle(i18n.t("deliveryAddress.modifyAddress.appTitle").toString());

const { setFieldValue, isSubmitting, handleSubmit } = useForm({
  validationSchema: {
    city: string().required().label('form.address.city.fieldName'),
    zipCode: string().required().label('form.address.zipCode.fieldName'),
    address: string().required().label('form.address.address.fieldName'),
    streetNumber: string().required().label('form.address.streetNumber.fieldName'),
    nation: string().required().label('form.address.nation.fieldName')
  },
});

const loading = ref(true);
onMounted(() => {
  if (props.index === undefined) goBack(router, route);
  else {
    const address = deliveryAddresses.value[parseInt(props.index)];
    setFieldValue('city', address.city);
    setFieldValue('zipCode', address.zipCode);
    setFieldValue('address', address.address);
    setFieldValue('streetNumber', address.streetNumber);
    setFieldValue('nation', address.nation);
    setFieldValue('isDefaultDeliveryAddress', address.isDefaultDeliveryAddress);
  }
  nextTick(() => loading.value = false);
});

const onDeleteAddress = async () => {
  const oldAddress =
    props.index !== undefined ? await deleteAddress(parseInt(props.index)) : null;

  if (
    oldAddress &&
    cart.value?.deliveryAddress &&
    isEqualAddress(oldAddress, cart.value?.deliveryAddress as Address)
  ) {
    setInitialCartDeliveryAddress();
    if (cart.value?.deliveryAddress) {
      deliveryAddress.value = cart.value?.deliveryAddress;
    }
  }

  setSnackbar(
    i18n.t("deliveryAddress.snackbar.addressRemoved").toString(),
    TypeSnackbar.SUCCESS
  );
  if(!auth.value.check()) router.push({ name: "order.deliveryAddress" });
  else router.push({ name: `${props.baseRoute}.deliveryAddress` });
};

const onValidSubmit = async (values: any) => {
  const newAddress = values as Address;
  if (props.index === undefined) return;
  if (newAddress && isAddressComplete(newAddress)) {
    try {
      await modifyAddress(parseInt(props.index), newAddress);

      setSnackbar(
        i18n.t("deliveryAddress.snackbar.addressModifiedSuccessfully").toString(),
        TypeSnackbar.SUCCESS
      );
      router.push({ name: `${props.baseRoute}.deliveryAddress` });
    } catch (e) {
      setErrorSnackbar(e as Error);
    }
  }
};

const onSubmit = handleSubmit(onValidSubmit);
</script>
