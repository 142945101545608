import { PaymentMethod, PaymentFlow } from "@/models/App";
import { computed, ref } from "vue";
import { useApp } from "../app";
import { useCart } from "@/store/cart";
import { isDeviceAndroid, isDeviceIOS } from "@/utils/userAgentData";
import { useSendOrder } from "./sendOrder";
import type { CloseResponse } from "@/api/Table";
import { useMenu } from "../menu";
import type { Product } from "@/models";
import useNetworkResponseDialog, {
  NetworkResponseDialogType
} from "../layout/networkResponseDialog";
import {
  close as closeTableApi,
  closeUnauthenticated as closeTableApiUnauthenticated
} from "@/api/Table";
import { useShowMoreDialog } from "../layout/moreDialog";
import { useTableGetters } from "@/store/table/modules/table";
import type { APIError } from "@/api/API";
import { usePaymentErrorsActions } from "@/store/table/modules/errors";
import { PaymentErrors } from "@/store/table";
import type { CreateOrderResponse } from "@/api/Order";
import { usePaymentGetters } from "@/store/table/modules/payment";
import type { Router } from "vue-router";

const productsOutOfStock = ref<Product[]>([]);
const submittingCheckout = ref<boolean>(false);

export function useCheckout() {
  const askCutlery = computed(() => {
    const { app } = useApp();
    const { cart } = useCart();
    if (!cart.value) return false;
    if (!app.value) return false;
    return app.value.services[cart.value.saletype].asking?.cutlery;
  });

  const proceedWithPayment = async (
    router: Router,
    paymentMethod: PaymentMethod,
    orderResponse: CreateOrderResponse | CloseResponse
  ) => {
    if (orderResponse && paymentMethod) {
      if ([PaymentMethod.FIDELITY_CARD, PaymentMethod.CASH_ON_DELIVERY].includes(paymentMethod)) {
        router.replace({ name: "payment.success", query: { orderId: orderResponse.id } });
      } else if (
        orderResponse.paymentGateway &&
        [
          PaymentMethod.POSTE_VIRTUAL_POS,
          PaymentMethod.NEXI_XPAY,
          PaymentMethod.PAYPAL,
          PaymentMethod.SATISPAY
        ].includes(orderResponse.paymentGateway)
      ) {
        if (orderResponse.paymentUrl) {
          let destinationUrl = new URL(orderResponse.paymentUrl);
          if (orderResponse.paymentGateway === PaymentMethod.NEXI_XPAY) {
            for(const [param, value] of Object.entries(orderResponse.paymentBody || {})) {
              destinationUrl.searchParams.append(param, value);
            }
          }
          window.location.assign(destinationUrl);
        } else {
          throw new Error("Something went wrong");
        }
      } else if (
        orderResponse.paymentGateway &&
        [PaymentMethod.WORLDLINE].includes(orderResponse.paymentGateway)
      ) {
        if (orderResponse.paymentId) {
          const scriptElement = document.createElement("script");
          scriptElement.onload = function() {
            // @ts-ignore
            const axeptaClient = new window.AxeptaSDKClient(
              orderResponse.sdkApiUrl,
              orderResponse.easyApiKey
            );
            axeptaClient.proceedToPayment(orderResponse.paymentId);
          };
          scriptElement.setAttribute("src", `${orderResponse.sdkApiUrl}/sdk/axepta-pg-redirect.js`);
          document.body.appendChild(scriptElement);
        }
      }
    }
  };

  const getOutOfStockProducts = async (
    outOfStockProducts: { productId: string; availableStockQuantity: number }[]
  ) => {
    const { cart } = useCart();
    if (!cart.value) return [];
    const { categories, fetchMenu } = useMenu(cart.value.saletype);
    await fetchMenu();
    const productsOutOfStock: Product[] = [];
    if (categories.value) {
      for (const category of categories.value) {
        for (const product of category.products) {
          const outOfStock = outOfStockProducts.find(
            outofStock => outofStock.productId === product.id
          );
          if (outOfStock) {
            product.warehouseItem = { availableStockQuantity: outOfStock.availableStockQuantity };
            productsOutOfStock.push(product);
          }
        }
      }
    }
    return productsOutOfStock;
  };

  const checkoutOrder = async (values: any, authenticated: boolean, router: Router) => {
    if(submittingCheckout.value) return;
    const { sendOrder } = useSendOrder();
    const [paymentMethod, moneyType] = values.paymentMethod.split('|');
    let orderResponse;
    try {
      submittingCheckout.value = true;
      orderResponse = await sendOrder({ ...values, paymentMethod, moneyType }, authenticated);
    } catch (e) {
      const error = e as APIError;
      if (error?.response?.status === 402) {
        // prepaid fail
        router.replace({
          name: "payment.error",
          query: {
            orderId: error.response.data.orderId,
            reason: "fidelityCard"
          }
        });
        return;
      } else if (error?.response?.status === 409) {
        if (!error.response?.data?.productsOutOfStock) throw error;
        const outOfStock = error.response?.data?.productsOutOfStock as {
          productId: string;
          availableStockQuantity: number;
        }[];
        productsOutOfStock.value = await getOutOfStockProducts(outOfStock);
        const { openNetworkResponseDialog } = useNetworkResponseDialog();
        openNetworkResponseDialog(NetworkResponseDialogType.EXCEEDING_STOCK_AVAILABILITY);
        const { cart } = useCart();
        if (!cart.value) return [];
        const { refreshMenu } = useMenu(cart.value.saletype);
        await refreshMenu();

        router.replace({ name: "cart" });
      } else {
        throw error;
      }
    } finally {
      submittingCheckout.value = false;
    }

    if (orderResponse && paymentMethod) {
      proceedWithPayment(router, paymentMethod, orderResponse);
    }
  };

  const closeTable = async (paymentMethod: PaymentMethod, authenticated: boolean, router: Router) => {
    const { id } = useTableGetters();
    const { paymentFlow, payingAmount, dutch, split } = usePaymentGetters();
    if (!id.value || !paymentMethod) return;
    let tableResponse;
    try {
      const closeApi = authenticated ? closeTableApi : closeTableApiUnauthenticated;
      tableResponse = await closeApi(id.value, {
        paymentMethod: paymentMethod,
        paidAmount: paymentFlow.value !== PaymentFlow.STANDARD ? payingAmount.value : undefined,
        dutchDivider:
          paymentFlow.value === PaymentFlow.DUTCH ? dutch.value?.dutchDivider : undefined,
        dutchPaying: paymentFlow.value === PaymentFlow.DUTCH ? dutch.value?.dutchPaying : undefined,
        payingItems: paymentFlow.value === PaymentFlow.SPLIT ? split.value?.payingItems : undefined,
        isDeposit: paymentFlow.value === PaymentFlow.DEPOSIT,
        isSplit: paymentFlow.value === PaymentFlow.SPLIT,
        isDutch: paymentFlow.value === PaymentFlow.DUTCH
      });
    } catch (e) {
      const error = e as APIError;
      const { setError } = usePaymentErrorsActions();
      const showMoreDialog = useShowMoreDialog();
      if (error?.response?.status === 402) {
        if (error.response.data?.details) {
          const details = error.response.data.details;
          if (details.status === PaymentErrors.REMAINING_CENTS_TO_PAY) {
            setError({ remainingCents: details.remainingCents });
            showMoreDialog.value = true;
            return;
          }
        }
      } else if (error?.response?.status === 403) {
        if (error.response.data?.details) {
          const details = error.response.data.details;
          if (details.status === PaymentErrors.PAYMENT_EXEEDS_TOTAL) {
            router.push({ name: "payment.paymentExceedsTotal" });
            return;
          }
          if (details.status === PaymentErrors.DUTCH_DIVIDER_MISMATCH) {
            setError({ dutchDividerMismatch: true });
            showMoreDialog.value = true;
            return;
          }
          if (details.status === PaymentErrors.ORDER_ITEMS_ALREADY_PAID) {
            return;
          }
        }
      }
      throw error;
    }

    proceedWithPayment(router, paymentMethod, tableResponse);
  };

  return {
    askCutlery,
    productsOutOfStock,
    submittingCheckout,
    proceedWithPayment,
    checkoutOrder,
    closeTable
  };
}
