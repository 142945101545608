<template lang="pug">
EatView.bg-neutral-a(:loading="loading")
  div.pb-3(v-scrollable="true" v-if="promoCodes.length")
    div.bg-white.rounded-xxl.mt-2.py-8.px-5(v-for="promoCode in promoCodes") 

      h2.text-h6 {{ promoCode.name }}
      div.pt-2(v-if="promoCode.note")
        p {{ promoCode.note }}

      div.pt-2
        div.text-caption.text-disabled {{ $t('promo.promoCode') }}
        p {{ promoCode.code }}

      div.d-flex.py-3 
        div {{ $t('promo.discount') }}
        div.ms-auto(v-if="promoCode.finalUnitaryPriceVariation.isPercentage") {{ promoCode.finalUnitaryPriceVariation.variation }}%
        div.ms-auto(v-else) {{ formatPrice(promoCode.finalUnitaryPriceVariation.variation) }}

      div.d-flex.align-center.border-t.py-3(v-if="promoCode.fidelityPointsForRedemption")
        div.w-50 {{ $t('promo.fidelityPointsForRedemption') }}
        div.ms-auto {{ promoCode.fidelityPointsForRedemption }} pt.

      div.d-flex.border-t.py-3(v-if="promoCode.constraints.maxAmountOfDiscount")
        div {{ $t('promo.maxDiscount') }}
        div.ms-auto {{ formatPrice(promoCode.constraints.maxAmountOfDiscount) }}

      div.d-flex.border-t.py-3(v-if="promoCode.validity.startDate || promoCode.validity.endDate")
        div(v-if="promoCode.validity.startDate && promoCode.validity.endDate") {{ $t('promo.validityPeriod') }}
        div(v-else-if="promoCode.validity.startDate") {{ $t('promo.validFrom') }}
        div(v-else-if="promoCode.validity.endDate") {{ $t('promo.validTo') }}
        div.ms-auto(v-if="promoCode.validity.birthdayValidity && user?.birthDate") {{ fromISO(user.birthDate).toFormat('dd-MM-yyyy') }}
        div.ms-auto(v-else)
          span(v-if="promoCode.validity.startDate") {{ fromISO(promoCode.validity.startDate).toFormat('dd-MM-yyyy') }}
          span(v-if="promoCode.validity.startDate && promoCode.validity.endDate") {{ ' / ' }}
          span(v-if="promoCode.validity.endDate") {{ fromISO(promoCode.validity.endDate).toFormat('dd-MM-yyyy') }}

      div.d-flex.border-t.py-3(v-if="promoCode.constraints.minNumberOfItems")
        div {{ $t('promo.weekDaysValidity') }}
        div.ms-auto 
          span(v-for="day, i in promoCode.validity.daysEnabled") {{ (!i ? '' : ' - ' ) + fromObject({ weekday: getWeekDay(day)  }).setLocale(i18n.locale.value).toFormat('ccc') }}

      div.d-flex.border-t.py-3(v-if="promoCode.validity.startTime || promoCode.validity.endTime")
        div {{ $t('promo.timeSlot') }}
        div.ms-auto 
          span(v-if="promoCode.validity.startTime") {{ fromISO(promoCode.validity.startTime).toFormat('hh:mm') }} 
          span(v-if="promoCode.validity.startTime && promoCode.validity.endTime") {{ ' - ' }}
          span(v-if="promoCode.validity.endTime") {{ fromISO(promoCode.validity.endTime).toFormat('hh:mm') }}

      div.d-flex.border-t.py-3(v-if="promoCode.constraints.minPurchase")
        div {{ $t('promo.minPurchase') }}
        div.ms-auto {{ promoCode.constraints.minPurchase }}

      div.d-flex.border-t.py-3(v-if="promoCode.constraints.minNumberOfItems")
        div {{ $t('promo.minProductsQuantity') }}
        div.ms-auto {{ promoCode.constraints.minNumberOfItems }}

      eat-btn.mt-5.bg-primary(:block="false" @click="copyPromoCode(promoCode.code)") {{ $t('promo.action.copyPromoCode') }}


  div.text-center.pt-15(v-else) {{ $t("promo.noActivePromotions") }}
</template>

<script setup lang="ts">
import { setAppTitle } from "@/store/appTitle";
import { usePromoCodes } from "@/store/promoCodes";
import { formatPrice } from "@/utils/formatPrice";
import { DateTime, type WeekdayNumbers } from "luxon";
import { TypeSnackbar, setSnackbar } from "@/store/layout/snackbar";
import { useUser } from "@/store/user";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
setAppTitle(i18n.t("promo.appTitle").toString());

const { promoCodes, loading } = usePromoCodes();
const { user } = useUser();
const { fromISO, fromObject } = DateTime;

const getWeekDay = (index: string) => parseInt(index) + 1 as WeekdayNumbers;

const copyPromoCode = (promoCode: string) => {
  setTimeout(async () => await navigator.clipboard.writeText(promoCode), 200);
  setSnackbar(
    i18n.t("promo.snackbar.promoCodeCopiedToClipboard").toString(),
    TypeSnackbar.SUCCESS
  );
};
</script>
