<template lang="pug">
SimpleFormView.bg-white(:loading="viewLoading")
  template(v-slot:title)
    span {{$t('user.editPassword.title')}}
  template(v-slot:text)
    span {{$t('user.editPassword.text')}}
  template(v-slot:form)
    form.flex-grow-1.d-flex.flex-column.justify-space-between.px-3(@submit="onSubmit")
      div.flex-grow-1
        EatField(name="password" v-slot="{ value, handleChange }")
          EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lock" :placeholder="$t('form.oldPassword.placeholder')")
          EatFieldMessage(name="oldPassword" hint="form.hint.required")
        EatField(name="newPassword" v-slot="{ value, handleChange }")
          EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lock" :placeholder="$t('form.newPassword.placeholder')")
          EatFieldMessage(name="password" hint="form.hint.required")
        EatField(name="passwordConfirm" v-slot="{ value, handleChange }")          
          EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lockSolid" :placeholder="$t('form.passwordConfirm.placeholder')")
          EatFieldMessage(name="passwordConfirm" hint="form.hint.required")   
        .text-center.pb-6
          router-link(:to="{name: 'auth.forgotPassword'}") 
            span.text-decoration-underline.text-black {{ $t('auth.login.forgotPassword') }}
      EatBtn.flex-grow-0.bg-primary(
        :loading="isSubmitting"
        type="submit"
      ) {{$t('general.action.save')}}  
      
  template(v-slot:extra)
    EatBtn.text-primary(
      :to="{ name: 'user.profileSettings' }"
    ) {{$t('general.action.cancel')}}               
</template>

<script setup lang="ts">
import { updatePassword as userUpdatePassword } from "@/api/User";
import { setAppTitle } from "@/store/appTitle";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { ref } from "vue";
import { goBack } from "@/store/routerHistory";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useForm } from "vee-validate";
import { object, string } from "yup";

interface PasswordUpdate {
  password: string;
  newPassword: string;
  passwordConfirm: string;
}

const viewLoading = ref(false);

const i18n = useI18n();
setAppTitle(i18n.t("user.editProfile.appTitle").toString());

const router = useRouter();
const route = useRoute();

const { handleSubmit, isSubmitting } = useForm({
  validationSchema: object({
    password: string().required().label('form.oldPassword.fieldName'),
    newPassword: string().required().label('form.newPassword.fieldName'),
    passwordConfirm: string().required()
      .test('passwords-match', '', function(value, ctx){
        if(this.parent.newPassword === value) return true;
        else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.passwordMatch', params: { label }}) });
      }).label('form.passwordConfirm.fieldName')
  })
});

const updatePassword = async (values: any) => {
  await userUpdatePassword(values as PasswordUpdate);
  setSnackbar(i18n.t("user.snackbar.infoUpdated").toString(), TypeSnackbar.SUCCESS);
  goBack(router, route);
};

const onSubmit = handleSubmit(updatePassword);
</script>
