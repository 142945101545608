<template lang="pug">
SimpleFormView.bg-white(:loading="viewLoading" v-if="activeView")
  template(v-slot:title)
    span {{$t(`user.editProfile.${activeView}.title`)}}
  template(v-slot:text)
    span {{$t(`user.editProfile.${activeView}.text`)}}
  template(v-slot:form)
    form.flex-grow-1.d-flex.flex-column.justify-space-between(v-if="user" @submit="onSubmit")
      div.flex-grow-1
        template(v-if="$route.query[EditProfileViews.NAME]")
          EatField(name="name" v-slot="{ value, handleChange }")
            EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$people" :placeholder="$t('form.name.placeholder')")
            EatFieldMessage(name="name" hint="form.hint.required")            
          EatField(name="surname" v-slot="{ value, handleChange }")
            EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$surname" :placeholder="$t('form.surname.placeholder')")
            EatFieldMessage(name="surname" hint="form.hint.required")

        template(v-else-if="$route.query[EditProfileViews.PHONE]")
          EatField(name="phone" v-slot="{ value, handleChange }")
            EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$mobile" :placeholder="$t('form.phone.placeholder')")
            EatFieldMessage(name="phone" hint="form.hint.required")

        template(v-else-if="$route.query[EditProfileViews.BIRTHDATE]")        
          EatField(name="birthDate" v-slot="{ value, handleChange }")
            EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="date" icon="$calendar" :placeholder="$t('form.phone.placeholder')" pattern="\d{2}-\d{2}-\d{4}")
            EatFieldMessage(name="birthDate" hint="form.hint.required")

        template(v-if="$route.query[EditProfileViews.GENDER]")
          EatField(name="gender" v-slot="{ value, handleChange }")
            EatSelect(:options="genders" :modelValue="value" @update:modelValue="handleChange" :placeholder="$t('form.gender.placeholder')")
            EatFieldMessage(name="gender")

      eat-btn.flex-grow-0.bg-primary(
        :loading="isSubmitting"
        type="submit"
      ) {{$t('general.action.save')}}  

  template(v-slot:extra)
    eat-btn(
      :text="true"
      color="text-primary"
      :to="{ name: 'user.profileSettings' }"
    ) {{$t('general.action.cancel')}} 
      
</template>

<script setup lang="ts">
import { updateProfile as userUpdateProfile } from "@/api/User";
import { goBack } from "@/store/routerHistory";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import type { NullRef } from "@/utils";
import { genderTranslations } from "@/utils/genderTranslations";
import { computed, onMounted, ref, watch } from "vue";
import { DateTime } from "luxon";
import { setAppTitle } from "@/store/appTitle";
import { useUser } from "@/store/user";
import type { User } from "@/models";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { string } from "yup";

enum EditProfileViews {
  NAME = "name",
  PHONE = "phone",
  BIRTHDATE = "birthDate",
  GENDER = "gender"
}

const router = useRouter();
const route = useRoute();
const i18n = useI18n();

setAppTitle(i18n.t("user.editProfile.appTitle").toString());

const activeView = computed(() => {
  if (route.query[EditProfileViews.NAME]) return EditProfileViews.NAME;
  if (route.query[EditProfileViews.PHONE]) return EditProfileViews.PHONE;
  if (route.query[EditProfileViews.BIRTHDATE]) return EditProfileViews.BIRTHDATE;
  if (route.query[EditProfileViews.GENDER]) return EditProfileViews.GENDER;
  return null;
});

watch(activeView, () => {
  if (activeView.value === null) goBack(router, route);
});

const viewLoading = ref(true);
const { user, refreshUser } = useUser();
const buttonLoading = ref(false);
const genders = ref(genderTranslations(i18n));
const showCompleteProfile: NullRef<string> = ref(null);

const validationSchema: Record<string, any> = {}
if(route.query[EditProfileViews.NAME]) {
  validationSchema.name = string().required().label('form.name.fieldName');
  validationSchema.surname = string().required().label('form.surname.fieldName');
}
if(route.query[EditProfileViews.PHONE]) {
  validationSchema.phone = string().required().label('form.phone.fieldName');
}
if(route.query[EditProfileViews.BIRTHDATE]) {
  validationSchema.birthDate = string().label('form.birthDate.fieldName');
}
if(route.query[EditProfileViews.GENDER]) {
  validationSchema.birthDate = string().label('form.birthDate.fieldName');
}
const { handleSubmit, isSubmitting, setFieldValue } = useForm({ validationSchema });

onMounted(async () => {
  if (activeView.value === null) goBack(router, route);
  viewLoading.value = true;
  user.value ? refreshUser() : await refreshUser();
  if (user.value) {
    setFieldValue('name', user.value.name);
    setFieldValue('surname', user.value.surname);
    setFieldValue('phone', user.value.phones?.length ? user.value.phones[0] : '');
    setFieldValue('gender', user.value.gender);
    setFieldValue('birthDate', user.value.birthDate ? DateTime.fromFormat(user.value.birthDate, "yyyy-MM-dd").toFormat('yyyy-MM-dd') : '');
  }
  viewLoading.value = false;

  showCompleteProfile.value = route.query.completeProfile as string | null;
});

const updateProfile = async (values: any) => {
  if (!user.value) return;
  buttonLoading.value = true;
  try {
    const newProfile: User = {
      name: values.name || user.value.name || "",
      surname: values.surname || user.value.surname || "",
      email: values.email || user.value.email || "",
      gender: values.gender || user.value.gender || "",
      phones: values.phone ? [values.phone] : user.value.phones || [],
      places: user.value.places || [],
      birthDate: values.birthDate || user.value.birthDate || ""
    };
    user.value = newProfile;
    await userUpdateProfile(newProfile);
  } finally {
    buttonLoading.value = false;
  }
  setSnackbar(i18n.t("user.snackbar.infoUpdated").toString(), TypeSnackbar.SUCCESS);
  goBack(router, route);
};

const onSubmit = handleSubmit(updateProfile)
</script>
