<template lang="pug">
div.bg-white.container.flex-grow-1   

  form.pt-6.pb-8(@submit="onSubmit")
    FacebookBtn
    div.d-flex.flex-col.mb-6.mt-8.mx-3.justify-center
      span {{ $t('auth.register.orEnterTheData') }}

    EatField(name="email" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="email" icon="$mail" placeholder="form.email.placeholder")
      EatFieldMessage(name="email" hint="form.hint.required") 
    EatField(name="name" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$people" placeholder="form.name.placeholder")
      EatFieldMessage(name="name" hint="form.hint.required")   
    EatField(name="surname" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$surname" placeholder="form.surname.placeholder")
      EatFieldMessage(name="surname" hint="form.hint.required")   
    EatField(name="password" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lock" placeholder="form.password.placeholder")
      EatFieldMessage(name="password" hint="form.hint.required")
    EatField(name="passwordConfirm" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="password" icon="$lockSolid" placeholder="form.passwordConfirm.placeholder")
      EatFieldMessage(name="passwordConfirm" hint="form.hint.required")
    EatField(name="phone" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$mobile" placeholder="form.phone.placeholder")
      EatFieldMessage(name="phone" hint="form.hint.required")
    EatField(name="birthDate" v-slot="{ value, handleChange }")
      EatTextInput(:modelValue="value" @update:modelValue="handleChange" type="date" icon="$calendar" pattern="\d{2}-\d{2}-\d{4}")
      EatFieldMessage(name="birthDate" :hint="isFieldMandatory(MandatoryFields.BIRTH_DATE) ? 'form.hint.required' : ''")
    EatField(name="gender" v-slot="{ value, handleChange }")
      EatSelect(:options="genders" :modelValue="value" @update:modelValue="handleChange" placeholder="form.gender.placeholder")
      EatFieldMessage(name="gender" :hint="isFieldMandatory(MandatoryFields.GENDER) ? 'form.hint.required' : ''")

    EatField(name="privacy" v-slot="{ value, handleChange }")
      EatCheckbox(:modelValue="value" @update:modelValue="handleChange")
        div {{ $t('auth.register.privacyPolicy') }}
          a.text-decoration-underline.ml-1(:href="app?.urlPrivacyPolicy || ''" @click.stop target="_self") {{ $t('auth.register.privacyPolicyLink') }}
      EatFieldMessage(name="privacy" hint="form.hint.required")
    EatField.mb-5(name="marketing" v-slot="{ value, handleChange }")
      EatCheckbox(:modelValue="value" @update:modelValue="handleChange")
        div {{ $t('auth.register.marketing') }}&nbsp;
          a.text-decoration-underline(v-if="app ? app.urlMarketing : ''" :href="app ? app.urlMarketing : ''" @click.stop target="_self") {{ $t('auth.register.marketingLink') }}
          span(v-else) {{ $t('auth.register.marketingLink') }}
    EatField.mb-5(name="profiling" v-slot="{ value, handleChange }")
      EatCheckbox(:modelValue="value" @update:modelValue="handleChange" v-if="app && app.profiling && !app.profiling.isHidden")
        div {{ $t('auth.register.profiling') }}&nbsp;
          a.text-decoration-underline(v-if="app.profiling.pdfUrl" :href="app.profiling.pdfUrl" @click.stop target="_self") {{ $t('auth.register.profilingLink') }}
          span(v-else) {{ $t('auth.register.profilingLink') }}

    EatField(name="g-recaptcha-response" v-slot="{ value, handleChange }")
      EatRecaptcha(:modelValue="value" @update:modelValue="handleChange")
      EatFieldMessage(name="g-recaptcha-response" hint="form.hint.required")
          
    EatBtn.bg-primary.mt-8(type="submit" :loading="isSubmitting") {{ $t('auth.register.createAccount') }}
</template>

<script setup lang="ts">
import FacebookBtn from "@/components/buttons/FacebookBtn.vue";
import { register as registerUser } from "@/api/User";
import { useApp } from "@/store/app";
import { genderTranslations } from "@/utils/genderTranslations";
import { ref } from "vue";
import { setAppTitle } from "@/store/appTitle";
import { MandatoryFields } from "@/models/App";
import { DateTime } from "luxon";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useForm } from "vee-validate";
import { boolean, object, string } from "yup";

interface RegisterForm {
  name: string;
  surname: string;
  email: string;
  gender: string;
  phone: string;
  phones: string[];
  birthDate: string;
  password: string;
  passwordConfirm: string;
  privacy: boolean;
  marketing: boolean;
  profiling: boolean;
  "g-recaptcha-response": string;
}

const i18n = useI18n();
const { app } = useApp();
setAppTitle(i18n.t("auth.register.appTitle").toString());

const genders = ref(genderTranslations(i18n));

const isFieldMandatory = (field: MandatoryFields) => {
  return app.value?.mandatoryFields ? app.value?.mandatoryFields[field] : false;
};

const { handleSubmit, isSubmitting, setFieldValue } = useForm({
  validationSchema: object({
    name: string().required().label('form.name.fieldName'),
    surname: string().required().label('form.surname.fieldName'),
    email: string().email().required().label('form.email.fieldName'),
    gender: (isFieldMandatory(MandatoryFields.GENDER) ? string().required() : string()).label('form.gender.placeholder'),
    phone: string().required().label('form.phone.fieldName'),
    birthDate: string().test('birthdate', '', function(value, ctx) {
      if(!value) {
        if(!isFieldMandatory(MandatoryFields.BIRTH_DATE)) return true;
        else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.invalid', params: { label }}) });
      } else if(/\d{4}-\d{2}-\d{2}/.test(value) && DateTime.fromFormat(value, "yyyy-MM-dd") < DateTime.now()) return true;
      else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.invalid', params: { label }}) });
    }).label('form.birthDate.placeholder'),
    password: string().required().label('form.password.fieldName'),
    passwordConfirm: string().required()
      .test('passwords-match', '', function(value, ctx){
        if(this.parent.password === value) return true;
        else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.passwordMatch', params: { label }}) });
      }).label('form.passwordConfirm.fieldName'),
    privacy: boolean()
      .oneOf([true], ({ label }) => ({ key: 'form.errors.required', params: { label }}))
      .required().label('form.privacy.fieldName'),
    "g-recaptcha-response": string().required()
  })
});

const onInvalidSubmit = async (e: any) => {
  setSnackbar(
    i18n.t("auth.register.snackbar.checkMandatoryFields").toString(),
    TypeSnackbar.WARNING
  );
};

const router = useRouter();
const register = async (values: any) => {
  const form = values as RegisterForm;
  try {
    if (form.birthDate) form.birthDate = DateTime.fromFormat(form.birthDate, "yyyy-MM-dd").toISO() || "";
    if (form.phone) form.phones = [form.phone];
    const oauthUser = await registerUser(form);
    if(oauthUser.confirmed) router.push({ name: "auth.login" });
    else router.push({ name: "auth.registerSuccess" });
  } finally {
    setFieldValue('g-recaptcha-response', '');
  }
};

const onSubmit = handleSubmit(register, onInvalidSubmit);
</script>
