import type { Composer } from "vue-i18n";

export const genderTranslations = (i18n: Composer) => {
  return [
    {
      value: "",
      label: i18n.t("form.gender.options.unknown").toString()
    },
    {
      value: "M",
      label: i18n.t("form.gender.options.male").toString()
    },
    {
      value: "F",
      label: i18n.t("form.gender.options.female").toString()
    }
  ];
};
