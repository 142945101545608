import { type CustomField, PaymentMethod, Saletype } from "@/models/App";
import type { CartItem } from "@/models/Cart";
import { DateTime } from "luxon";
import { createOrder, createOrderUnauthenticated } from "@/api/Order";
import { useApp } from "../app";
import { useCart } from "../cart";

export function useSendOrder() {
  const getModulesIds = (item: CartItem) => {
    return Object.entries(item.configuration.modules).map(([name, products]) => ({
      name: name,
      choosenProducts: products.map(p => p.id),
      upsellingProducts: (item.configuration.upsellingModules[name] || []).map(p => p.id)
    }));
  };

  const getArticleAttributes = (item: CartItem) => {
    return Object.entries(item.configuration.articleAttributes).map(([id, attr]) => ({
      attributeTypeId: id,
      attributeValue: attr.value
    }));
  };

  const formatOrderItem = (item: CartItem) => {
    const addedComponents = item.configuration.addedComponents.map(ac => ac.productId);
    const mandatoryComponents = item.configuration.mandatoryComponents?.map(mc => mc.productId) || [];
    const removedComponents = item.configuration.removedComponents.map(rc => rc.productId);
    return {
      productId: item.product.id,
      quantity: item.quantity,
      addedComponents: [...addedComponents, ...mandatoryComponents],
      removedComponents: removedComponents,
      modules: getModulesIds(item),
      articleAttributes: getArticleAttributes(item)
    };
  };

  const formatOrder = (values: any) => {
    const { cart } = useCart();
    if (!cart.value) return;
    const { customFields } = useApp();
    const customFieldsToSend: CustomField[] = [];

    const deliveryAddress =
      cart.value.saletype === Saletype.DELIVERY && cart.value.deliveryAddress
        ? cart.value.deliveryAddress
        : null;
    const deliveryServiceChargeId =
      cart.value.saletype === Saletype.DELIVERY && cart.value.deliveryPrice?.serviceChargeId
        ? cart.value.deliveryPrice?.serviceChargeId
        : null;

    let date = "";
    if ([Saletype.DELIVERY, Saletype.TAKEAWAY].includes(cart.value.saletype)) {
      date = cart.value.orderTimeSlot?.timeSlot?.to as string;
    } else if (cart.value.saletype === Saletype.TAKEAWAY_ON_SITE) {
      date = DateTime.now().plus({ minutes: 5 }).toString();
      
      for(const field of (customFields.value || [])) {
        if(!['name', 'covers'].includes(field.name)) {
          customFieldsToSend.push({ ...field, value: values.customFields[field.name] });
        }
      }
    }

    return {
      saleType: cart.value.saletype,
      paymentMethod: values.paymentMethod as PaymentMethod,
      moneyType: values.moneyType,
      priceListId: cart.value.priceList,
      name: values.name || values.customFields?.name,
      covers: values.covers || values.customFields?.covers,
      customFields: customFieldsToSend,
      note: values.note,
      invoiceData: values.invoiceData?.isInvoiceRequested ? values.invoiceData : undefined,
      date,
      deliveryAddress,
      deliveryServiceChargeId,
      customerRequests: { cutlery: values.cutlery },
      promoCodes: cart.value.promoCodes,
      affiliationDiscount: !!cart.value?.affiliationDiscount
    };
  };

  const getApplyDiscountParams = (values: any) => {
    const { cart } = useCart();
    const orderItems = [];
    const order = formatOrder(values);
    if (!order) return;
    for (const item of cart.value?.items || []) {
      if (item.quantity) {
        orderItems.push({
          ...formatOrderItem(item),
          finalUnitaryPriceVariation: item.variation
        });
      }
    }

    let subTotalItem;
    if (cart.value?.subtotalVariation) {
      subTotalItem = { finalUnitaryPriceVariation: cart.value?.subtotalVariation };
    }

    return { ...order, orderItems, subTotalItem };
  };

  const getCreateOrderParams = (values: any) => {
    const { cart } = useCart();
    const order = formatOrder(values);
    if (!order) return;
    const orderItems = [];
    for (const item of cart.value?.items || []) {
      if (item.quantity) orderItems.push(formatOrderItem(item));
    }

    return { ...order, orderItems };
  };

  const sendOrder = async (values: any, authenticated: boolean) => {
    const createOrderParams = getCreateOrderParams(values);
    if (!createOrderParams) return;
    return authenticated
      ? await createOrder(createOrderParams)
      : await createOrderUnauthenticated(createOrderParams);
  };

  return {
    sendOrder,
    formatOrder,
    getApplyDiscountParams
  };
}
