const DOMURL = window.URL || window.webkitURL || window;

function triggerDownload(URI: string, fileName: string) {
  const evt = new MouseEvent("click", {
    view: window,
    bubbles: false,
    cancelable: true
  });
  const a = document.createElement("a");
  a.setAttribute("download", fileName);
  a.setAttribute("href", URI);
  a.setAttribute("target", "_blank");
  a.dispatchEvent(evt);
  a.remove();
}

export function downloadSVG(svgCode: string) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  const img = new Image();
  const ns =
    '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
  const svgBlob = new Blob([ns + svgCode], { type: "image/svg+xml;charset=utf-8" });
  const url = DOMURL.createObjectURL(svgBlob);

  img.onload = function() {
    canvas.width = 1000;
    canvas.height = 1000;
    ctx.drawImage(img, 0, 0, 1000, 1000);
    const pngImg = canvas.toDataURL("image/png");
    triggerDownload(pngImg, "qrcode.png");
    DOMURL.revokeObjectURL(url);
  };
  img.src = url;
}

function downloadFile(buffer: BlobPart, type: Type, fileName: string) {
  const blob = new Blob([buffer], { type: type });
  const url = URL.createObjectURL(blob);

  triggerDownload(url, fileName);
  DOMURL.revokeObjectURL(url);
}

enum Type {
  pkPass = "application/vnd.apple.pkpass",
  ics = "text/calendar"
}

export function downloadPkPass(pkPass: BlobPart) {
  downloadFile(pkPass, Type.pkPass, "fidelity_card.pkpass");
}

export function downloadReservationPkPass(pkPass: BlobPart) {
  downloadFile(pkPass, Type.pkPass, "reservation.pkpass");
}

export function downloadIcs(ics: BlobPart) {
  downloadFile(ics, Type.ics, "reservation.ics");
}
