import { computed } from "vue";
import { type PaymentErrorsState, useGlobalState } from "..";

export const usePaymentErrorsGetters = () => {
  const remainingCents = computed(() => {
    const { payment } = useGlobalState();
    return payment.value.errors?.remainingCents || 0;
  });
  const dutchDividerMismatch = computed(() => {
    const { payment } = useGlobalState();
    return payment.value.errors?.dutchDividerMismatch;
  });

  return {
    remainingCents,
    dutchDividerMismatch
  };
};

const usePaymentErrorsMutations = () => {
  const SET_ERROR = (error: PaymentErrorsState | null) => {
    const { payment } = useGlobalState();
    payment.value.errors = error;
  };

  return {
    SET_ERROR
  };
};

export const usePaymentErrorsActions = () => {
  const setError = (error: PaymentErrorsState | null) => {
    const { SET_ERROR } = usePaymentErrorsMutations();
    SET_ERROR(error);
  };

  const clearError = () => {
    const { SET_ERROR } = usePaymentErrorsMutations();
    SET_ERROR(null);
  };

  return { setError, clearError };
};
