<template lang="pug">
div.account-top-bar.border.border-clr-white.bg-white.rounded-t-xxl(ref="$container")
    div.profile-image__motion-container
      svg.motion-path(xmlns="http://www.w3.org/2000/svg")
        path(:d="`M 0 0 Q 21 70 ${imageMotionPathWidth} 70`" v-animatable="{ name: AccountAnimatables.MOTION_PATH, animations: [AccountAnimations.TOP_BAR] }" stroke="black" stroke-width="1" fill="black")
      div.profile-image__container.text-h6.d-flex.items-center.flex-shrink-0(v-animatable="{ name: AccountAnimatables.PROFILE_IMAGE, animations: [AccountAnimations.TOP_BAR] }" :style="`border: 3px solid #fff;`")
        img.profile-image(src="@/assets/images/placeholder_customer.jpg")
    div.top-bar.rounded-t-xxl(v-animatable="{ name: AccountAnimatables.TOP_BAR, animations: [AccountAnimations.TOP_BAR] }")
      div.profile-name.font-weight-medium.px-4(v-animatable="{ name: AccountAnimatables.NAME, animations: [AccountAnimations.TOP_BAR] }") {{ user ? `${user.name} ${user.surname}` : "" }}
      EatIconBtn.btn-more(@click="showMoreDialog = true" icon="$more" :size="18")
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { AccountAnimatables, AccountAnimations } from "@/store/animations/account";
import { useUser } from "@/store/user";
import { useShowMoreDialog } from "@/store/layout/moreDialog";

const $container = ref();
const { user } = useUser();
const imageMotionPathWidth = ref(0);
onMounted(() => {
  imageMotionPathWidth.value = ($container.value?.clientWidth || 0) / 2 - 48 - 16;
});

const showMoreDialog = useShowMoreDialog();
</script>

<style lang="scss" scoped>
$height-unit: 64px;
.account-top-bar {
  position: relative;
}

.profile-image__motion-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 64px;
  pointer-events: none;

  .motion-path {
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 16px;
    opacity: 0;
  }

  .profile-image__container {
    position: absolute;
    top: 70px;
    left: 50%;
    min-width: 96px;
    max-width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    transform: translateX(-50%);
    transition: transform 0.3s ease;
    z-index: 2;
    .profile-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }
}

.top-bar {
  padding-bottom: 48px;

  .profile-name {
    width: max-content;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 22px;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .btn-more {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
