<template lang="pug">
div.border-b.bg-primary
  div.px-5.py-3.flex-grow-0
    EatSearchBar.elevation-1(v-model="inputAddress" icon="$search")
      template(v-slot:append-content)
        EatIconBtn(icon="$position" :loading="isLoadingGeolocation" @click="onGeolocationClick")    
</template>

<script setup lang="ts">
import EatSearchBar from "@/components/utils/EatSearchBar.vue";
import { useAutocompletes } from "@/store/delivery/autocompletes";
import { useGeolocation, useNewAddress } from "@/store/delivery/newAddress";
import { setErrorSnackbar, setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { getGeocodingFromGeolocation } from "@/utils/googleUtils";
import { onMounted, ref } from "vue";
import { throttledWatch } from "@vueuse/shared";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { autocompletes, getAutocompletes, inputAddress } = useAutocompletes();
const i18n = useI18n();
const router = useRouter();
const route = useRoute();
const isDeliverySelector = ref(false);
const { newAddress } = useNewAddress();

throttledWatch(
  inputAddress,
  async () => {
    if (inputAddress.value !== null && inputAddress.value !== undefined) {
      autocompletes.value = await getAutocompletes((i18n.locale.value));
    }
  },
  { throttle: 300 }
);

onMounted(() => {
  isDeliverySelector.value = !!route.meta.isDeliverySelector;
});

const { geolocalize } = useGeolocation();
const isLoadingGeolocation = ref(false);
const onGeolocationClick = async () => {
  isLoadingGeolocation.value = true;
  try {
    const position = await geolocalize(5000);
    newAddress.value = await getGeocodingFromGeolocation(position);
    router.push({
      name: isDeliverySelector ? "order.newAddress" : "user.newAddress",
      query: { validate: "true" }
    });
  } catch (e) {
    const error = e as { code: number; name: string; message: string };
    if (error?.code === 3) {
      setSnackbar(
        i18n.t("deliveryAddress.snackbar.geolocationTimeout").toString(),
        TypeSnackbar.WARNING
      );
    } else {
      setErrorSnackbar(error as Error, 3000);
    }
  } finally {
    isLoadingGeolocation.value = false;
  }
};
</script>

<style lang="scss" scoped>
.text-field__container {
  border: 1px solid #b2c0cd;
  min-height: 48px;
  width: 100%;
}

.text-field {
  position: relative;

  &.text-field__error::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #f23a30;
  }

  .text-field__prepend {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 16px 16px 16px;
  }

  .text-field__append {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 16px 16px 16px;
  }

  .text-field__input {
    &:focus {
      outline: none;
    }
    height: 48px;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
