<template lang="pug">
EatView.bg-neutral-a(:isScrollable="true")        
  template(v-slot:default)
    EatTabs.flex-grow-1(:tabs="tabs")
</template>

<script setup lang="ts">
import EatTabs, { type Tab } from "@/layout/partials/EatTabs.vue";

const tabs: Tab[] = [
  { title: "auth.login.title", routeName: "auth.login" },
  { title: "auth.register.title", routeName: "auth.register" }
];
</script>
