<template lang="pug">
SuccessView.bg-success
  template(v-slot:icon)
    EatIcon.mb-5(:size="112" color="white" icon="$lockOpen") 
  template(v-slot:title) {{$t('auth.resetPasswordSuccess.title')}}
  template(v-slot:text) {{$t('auth.resetPasswordSuccess.text')}}
  template(v-slot:action)
    eat-btn.flex-grow-0.mt-15.bg-white(:to="{ name: 'auth.login' }") {{ $t('auth.resetPasswordSuccess.goToLogin') }}
</template>

<script setup lang="ts">
import SuccessView from "@/layout/AuthSuccessView.vue";
import { setAppTitle } from "@/store/appTitle";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
setAppTitle(i18n.t("auth.resetPasswordSuccess.appTitle").toString());
</script>
