import { ref } from "vue";

export enum NetworkResponseDialogType {
  EXCEEDING_STOCK_AVAILABILITY = "ExceedingStockAvailabilityDialog"
}

const showDialog = ref(false);
const responseType = ref<NetworkResponseDialogType>();

const useNetworkResponseDialog = () => {
  const openNetworkResponseDialog = (type: NetworkResponseDialogType) => {
    responseType.value = type;
    showDialog.value = true;
  };

  return {
    showDialog,
    responseType,
    openNetworkResponseDialog
  };
};

export default useNetworkResponseDialog;
